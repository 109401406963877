<template>
  <div class="tw-p-5">
    <div class="tw-flex tw-items-center tw-mb-[25px] tw-h-6">
      <h1 class="tw-font-bold tw-text-[18px] tw-text-[#3e3e3e] tw-flex-grow">Синхронизация с AMO</h1>
      <img
        v-if="!editSettings && !isSavingParams"
        v-tippy="{content: 'Редактировать'}"
        :src="require('@/assets/icons/edit.svg')"
        alt="#"
        class="tw-h-4 tw-cursor-pointer"
        @click="editSettings = true"
      />
      <template v-else>
        <zem-link v-if="!isSavingParams" class="mr-2 mobile-d-none" @click="editSettings = false">
          {{ $t('Cancel') }}
        </zem-link>
        <zem-button
          :disabled="isSavingParams"
          :loading="isSavingParams"
          class="mobile-d-none"
          @click="onSaveSystemSettings"
        >
          {{ isSavingParams ? 'Сохранение' : $t('Save') }}
        </zem-button>
      </template>
      <div v-if="!isSavingParams" @click="closeSidebar">
        <zem-icon class="cursor-pointer ml-2" name="close" size="20" />
      </div>
    </div>
    <div v-if="!params" class="d-flex justify-content-center mt-2">
      <zem-preloader color="primary" size="18" />
    </div>
    <template v-else>
      <zem-collapse v-for="setting in params" :key="setting['key']" :title="setting['title']">
        <div class="zem-collapse-table rows mt-2">
          <div v-for="(value, index) in Object.keys(setting.value)" :key="index" class="zem-collapse-table__row">
            <div v-tippy="{content: setting.value[value].title}" class="zem-collapse-table__column title">
              {{ setting.value[value].title }}
            </div>
            <div
              v-tippy="{content: formatValue(setting.value[value])}"
              :class="{'overflow-unset': setting.value[value].type === 'list'}"
              class="zem-collapse-table__column text"
            >
              <zem-button
                v-if="setting.value[value].type === 'link'"
                v-tippy="{content: setting.value[value]['hint']}"
                :disabled="isLoadingUpdateUsers"
                :loading="isLoadingUpdateUsers"
                class="mt-0"
                @click="updateUsers(setting.value[value]['value'])"
                >Обновить
              </zem-button>
              <template v-else-if="!editSettings">{{ formatValue(setting.value[value]) }}</template>
              <ZemDropdownList
                v-else-if="setting.value[value].type === 'list'"
                v-model="setting.value[value].value"
                v-tippy="{content: setting.value[value]['hint']}"
                :options="setting.value[value].options"
                class="mt-0"
                item="title"
                type="object"
              />
              <zem-input
                v-else-if="setting.value[value]['hint']"
                v-model="setting.value[value].value"
                v-tippy="{content: setting.value[value]['hint']}"
                class="mt-0"
              />
              <zem-input v-else v-model="setting.value[value].value" class="mt-0" />
            </div>
          </div>
        </div>
      </zem-collapse>
    </template>

    <ZemModal
      :show="showModal"
      :title-cancel="$t('No') || ''"
      :title-success="$t('Yes') || ''"
      title="Уверены, что хотите выйти?"
      @on-change="showModal = !showModal"
      @on-success="logOut"
      @on-cancel="showModal = false"
    />
  </div>
</template>

<script>
import Tabs from '@/components/ui/Tabs'
import Security from '@/views/Settings/SettingsSecurity'
import Settings from '@/views/Settings/SettingsAmoCrm'
import SettingsCompany from '@/views/Settings/SettingsCompany'
import SettingsProfile from '@/views/Settings/SettingsProfile'
import {formattedPhone} from '@/assets/scripts/scripts'
import ZemLink from '@/components/ui/ZemLink.vue'
import UserCard from '@/components/UserCard.vue'
import ZemInput from '@/components/ui/ZemInput.vue'
import ZemCollapse from '@/components/ui/ZemCollapse.vue'
import TokenService from '@/services/token.service'
import ZemModal from '@/components/ui/ZemModal.vue'
import ZemDropdownList from '@/components/ui/ZemDropdownList.vue'
import ZemButton from '@/components/ui/ZemButton.vue'
import ZemIcon from '@/components/ui/ZemIcon.vue'
import OtherService from '@/services/other.service'
import ZemPreloader from '@/components/ui/ZemPreloader.vue'
import PeopleAndCompaniesService from '@/services/peopleAndCompanies.service'
import axios from 'axios'
import {auth} from '@/helpers/config-axios'

export default {
  components: {
    ZemIcon,
    ZemButton,
    ZemDropdownList,
    ZemModal,
    ZemCollapse,
    ZemInput,
    UserCard,
    ZemLink,
    SettingsProfile,
    SettingsCompany,
    Tabs,
    Security,
    Settings,
    ZemPreloader,
  },
  data() {
    return {
      selectedTab: 'Пользователь',
      itemInfo: {
        type: 'people',
        name: 'Иванов Сергей Петрович',
        phone: '',
        email: '',
        telegram: '',
        id: '123567',
        status: '',
        img: require('@/assets/images/user.webp'),
      },
      newCompanyFields: [
        {
          name: 'ИНН',
          value: '',
        },
        {
          name: 'Название',
          value: '',
        },
        {
          name: 'Страна',
          value: '',
        },
        {
          name: 'Регион',
          value: '',
        },
        {
          name: 'Город',
          value: '',
        },
        {
          name: 'Тип организации',
          value: '',
        },
      ],
      fields: [],
      editProfile: false,
      editSettings: false,
      showModal: false,
      showChangePasswordModal: false,
      unloadingProcess: false,
      downloadUrl: '',
      currentFields: [],
      errorsBackend: [],
      selectLanguage: {
        locale: 'ru',
        title: 'Русский',
      },
      selectBrand: {
        object: null,
        title: 'Выберите бренд',
      },
      locales: [
        {
          locale: 'en',
          title: 'English',
        },
        {
          locale: 'ru',
          title: 'Русский',
        },
      ],
      oldPassword: '',
      newPassword: '',
      repeatPassword: '',
      params: null,
      loadingParams: true,
      isSavingParams: false,
      timer: null,
      optionsOwnership: [],
      optionsCities: [],
      optionsCountries: [],
      optionsRegions: [],
      isLoadingUpdateUsers: false,
    }
  },
  computed: {
    profile() {
      return this.$store.state.user.profile
    },
    priorityOrganization() {
      return this.profile.human.data.priority_organization_id
    },
  },
  watch: {
    profile() {
      this.getUserData()
    },
  },
  mounted() {
    // OtherService.getSettings()
    //   .then(r => {
    //     this.params = r.data.data.filter(el => el.key === "amo_crm")[0].value
    //   })
    // ProfileService.getOwnershipList().then(r => {
    //   this.optionsOwnership = r.data.data
    // })
    // ProfileService.getCountriesList().then(r => {
    //   this.optionsCountries = r.data.data
    // })
    // ProfileService.getCitiesList().then(r => {
    //   this.optionsCities = r.data.data
    // })
    // ProfileService.getRegionsList().then(r => {
    //   this.optionsRegions = r.data.data
    // })
    this.$store.commit('profile/getCompaniesSearch', [])
    this.getUserData()
    if (this.menuType === 'settings') {
      OtherService.getSettingsSystem().then(r => {
        this.params = r.data.data
        this.loadingParams = false
      })
    }
  },
  methods: {
    formatValue(value) {
      if (!value.value) return ''
      if (value.type === 'list') return value.value.title
      return value.value
    },
    updateUsers(link) {
      this.isLoadingUpdateUsers = true
      axios
        .get(link, {headers: auth()})
        .then(() => {
          this.isLoadingUpdateUsers = false
          this.$toast.success('Список AmoCrm пользователей обновлен!')
        })
        .catch(e => {
          if (e.request.status === 417) {
            this.$toast.error(e.response.data.message)
          } else {
            this.$toast.error(JSON.parse(e.request.responseText).message)
          }
        })
    },
    getUserData() {
      const phone = this.profile.human.data.phone

      this.itemInfo = {
        type: 'people',
        name: this.profile.human.data.fio,
        phone: formattedPhone(phone),
        email: this.profile.email,
        telegram: '8 912 123 45 67',
        id: this.profile.human.data.number,
        id2: this.profile.id,
        status: 'Активный',
        img: require('@/assets/images/user.webp'),
      }

      this.fields = [
        {
          title: 'Контакты',
          data: [
            {
              name: 'ФИО',
              value: this.profile.human.data.fio,
              new: false,
              type: 'text',
              read: true,
            },
            {
              name: 'Телефон',
              value: formattedPhone(phone),
              type: 'phone',
              new: false,
              read: true,
            },
            {
              name: 'Email',
              value: this.profile.email,
              type: 'email',
              new: false,
              read: true,
            },
          ],
          possibleToCreate: [
            {
              name: 'Контакт',
            },
          ],
        },
      ]

      if (this.priorityOrganization)
        PeopleAndCompaniesService.getCompany(this.priorityOrganization).then(r => {
          const {city, country, inn, organization_ownership, region, title} = r.data.data
          this.newCompanyFields = [
            {
              name: 'ИНН',
              value: inn,
            },
            {
              name: 'Название',
              value: title,
            },
            {
              name: 'Страна',
              value: country ? {id: country.data.id, title: country.data.name} : '',
            },
            {
              name: 'Регион',
              value: region ? {id: region.data.id, title: region.data.name} : '',
            },
            {
              name: 'Город',
              value: city ? {id: city.data.id, title: city.data.name} : '',
            },
            {
              name: 'Тип организации',
              value: organization_ownership ? organization_ownership.data : '',
            },
          ]
        })
    },
    logOut() {
      this.$store.commit('profile/showProfile', false)
      this.showModal = false
      localStorage.removeItem('user')
      TokenService.deleteLocalAccessToken()
      TokenService.deleteLocalRefreshToken()
      this.$store.dispatch('auth/logout')
      this.$router.push('/login')
    },
    async onSaveSystemSettings() {
      this.editSettings = false
      this.isSavingParams = true
      let data = {}
      for (const i in this.params) {
        data = {
          key: this.params[i].key,
          value: {},
          type: 'data',
        }

        for (const v in this.params[i].value) {
          const {type, value} = this.params[i].value[v]
          data.value[v] = type === 'list' ? value.value : value
        }
        await OtherService.updateSettings(data).then(() => {
          if (Number(i) === this.params.length - 1) {
            this.$toast.success('Настройки системы сохранены')
            this.isSavingParams = false
          }
        })
      }
    },
    closeSidebar() {
      this.$store.commit('profile/showProfile', false)
      this.$store.commit('profile/showSyncAmo', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.select {
  width: 100%;
}

.new-company {
  width: 470px;
  margin-top: 12px;

  &__input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;

    &__error {
      font-size: 10px;
      color: $color-red;
      margin: 5px 0 0 0;
    }
  }

  &__column {
    flex: 1;

    &.title {
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      color: $color-gull-gray;
      margin-right: 10px;
      padding-top: 5px;
    }

    &--second {
      flex: 1;
      cursor: pointer;
    }

    &--select {
      width: 244px;
      justify-content: space-between !important;
      align-items: center !important;
    }
  }

  &__row {
    display: flex;
    align-items: flex-start;
    align-self: flex-start;
    align-content: inherit;
    text-align: left;
    justify-content: flex-start;
    margin-bottom: 8px;
  }
}
</style>
