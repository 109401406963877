import $api from '@/helpers/config-axios'

import authHeader from './auth-header'
import store from '@/store'
import {errorHandler} from '@/assets/scripts/scripts'
import OtherService from '@/services/other.service'
import {eventBus} from '@/main'

class UsersService {
  getAllUsers(list = false, loading = true) {
    if (loading) store.commit('other/onChangeLoadingItems', true)

    const {orderBy, sortedBy, search, paginationPage, filter, isArchive} = store.state.users

    let newParamsForUsers = {
      page: paginationPage,
      include: 'roles,human.organizations,human.priority_organization,amoCrmUser,params_schema',
    }
    if (orderBy !== null) newParamsForUsers['orderBy'] = orderBy
    if (sortedBy !== null) newParamsForUsers['sortedBy'] = sortedBy
    if (filter !== null) newParamsForUsers['search'] = filter.main
    if (search !== '') newParamsForUsers['search'] = search
    if (list) newParamsForUsers['to'] = 'list'

    if (isArchive) newParamsForUsers['only-trashed'] = `1`

    return $api
      .get('users', {
        params: newParamsForUsers,
        headers: authHeader(),
      })
      .then(r => {
        store.commit('users/getAllUsers', r.data)
        if (loading) store.commit('other/onChangeLoadingItems', false)
        eventBus.$emit('update-table-settings', [])
        return r
      })
  }

  getUsersList() {
    return $api.get('users', {
      params: {
        to: 'list',
        limit: '*',
      },
      headers: authHeader(),
    })
  }

  async getSelectedUsers() {
    store.commit('other/onChangeLoadingItem', true)
    if (
      OtherService.handlerOptions(store.state.users.selectedItemsForDelete, 'users/getSelectedUsers').search !==
      undefined
    ) {
      return await $api
        .get('users', {
          params: OtherService.handlerOptions(store.state.users.selectedItemsForDelete, 'users/getSelectedUsers'),
          headers: authHeader(),
        })
        .then(r => {
          store.commit('users/getSelectedUsers', r.data)
          store.commit('other/onChangeLoadingItems', false)
          eventBus.$emit('update-table-settings', [])
        })
        .catch(e => {
          store.commit('users/getSelectedUsers', {data: []})
          errorHandler(e)
        })
    }
  }

  async getUser(id) {
    return await $api.get(`users/${id}`, {
      params: {
        include: 'roles,human.organizations,human.priority_organization,amoCrmUser,params_schema',
      },
      headers: authHeader(),
    })
  }

  async deleteUser(id, last) {
    return await $api
      .delete('users/' + id, {
        headers: authHeader(),
      })
      .then(() => {
        if (last) this.getAllUsers()
      })
      .catch(e => {
        errorHandler(e)
      })
  }

  async updateUser(id, data, last) {
    return await $api
      .patch('users/' + id, data, {
        headers: authHeader(),
      })
      .then(r => {
        if (last) this.getAllUsers()
        return r
      })
      .catch(e => {
        errorHandler(e)
      })
  }

  updateRoleForUser(userId, roleId) {
    return $api
      .post(
        'roles/sync',
        {
          user_id: userId,
          roles_ids: roleId,
        },
        {
          headers: authHeader(),
        }
      )
      .then(async r => {
        await this.getAllUsers()
        return r
      })
      .catch(e => {
        errorHandler(e)
      })
  }

  deleteItems(permanent = false) {
    let newParams = {}
    if (permanent) newParams['force-delete'] = 1

    return $api.delete('users', {
      params: newParams,
      headers: authHeader(),
      data: {
        ids: store.state.users.selectedItemsForDelete,
      },
    })
  }

  archiveItems() {
    return $api.post(
      'restore-users',
      {
        ids: store.state.users.selectedItemsForDelete,
      },
      {
        headers: authHeader(),
      }
    )
  }

  getUsersAmoCRM() {
    return $api
      .get('amo-crm/users?to=list', {
        headers: authHeader(),
      })
      .then(data => {
        store.commit('users/getUsersAmoCRM', data.data)
      })
      .catch(e => {
        errorHandler(e)
      })
  }
}

export default new UsersService()
