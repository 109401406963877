<template>
  <div v-click-outside="vcoConfig" class="sidebar-left">
    <div class="sidebar-left__container sidebar-left__container--show">
      <slot />
    </div>
  </div>
</template>

<script>
import Arrow from './ui/Arrow'

export default {
  props: {
    isHuge: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    Arrow,
  },

  data() {
    return {
      isSideBarVisible: true,
      vcoConfig: {
        handler: this.onClickOutside,
        middleware: this.middleware,
        events: ['dblclick', 'click'],
        isActive: true,
        detectIFrame: true,
        capture: false,
      },
    }
  },

  methods: {
    slideEvent() {
      this.$emit('slide', this.isSideBarVisible)
    },
    onClickOutside() {
      if (this.isHuge) {
        this.isSideBarVisible = false
        this.slideEvent()
      }
    },
    middleware(event) {
      return event.target.className !== 'modal'
    },
  },
}
</script>
