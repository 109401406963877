<template>
  <div v-click-outside="vcoConfig" :class="{disabled: disabled}" :tabindex="tabindex" class="zem-dropdown">
    <div
      v-if="!search"
      :class="{open: open, 'text-muted': isPlaceholder}"
      class="zem-dropdown-head"
      @click="onChangeStatus(false)"
    >
      <div class="text-truncate">
        <span>{{ inputValue }}</span>
      </div>
      <div v-if="loading" class="zem-dropdown-head__preloader">
        <zem-preloader color="primary" />
      </div>
      <img
        alt="drd-arrow"
        class="zem-dropdown-head__arrow"
        src="../../assets/icons/dropdown-arrow.svg"
        @click="onChangeStatus(false)"
      />
    </div>
    <div v-else class="zem-dropdown-head__search">
      <zem-input
        v-if="search"
        :disabled="disabled"
        :placeholder="placeholder"
        :value="inputValue"
        class="mt-0"
        is-dropdown
        @focus="updateOptions"
        @input="updateOptions($event, true)"
      />
      <zem-preloader
        v-if="loading"
        :style="{right: `${selected.title ? '44' : '26'}px`}"
        class="tw-top-1.5"
        color="primary"
      />
      <img
        v-if="selected && selected.title && !disabled"
        alt="clear-icon"
        class="clear-icon"
        src="../../assets/icons/close-m.svg"
        @click="onDeleteItems"
      />
      <img
        v-if="!disabled"
        alt="drd-arrow"
        class="zem-dropdown-head__arrow"
        src="../../assets/icons/dropdown-arrow.svg"
        @click="onChangeStatus(false)"
      />
    </div>
    <div
      :class="[
        {
          'tw-transition tw-ease-out tw-duration-100 tw-transform tw-opacity-0 tw-scale-95': !open,
          'tw-transition tw-ease-in tw-duration-75 tw-transform tw-opacity-100 tw-scale-100': open,
          'tw-hidden': isHidden,
          'tw-right-0': isRight,
        },
      ]"
      class="tw-absolute tw-w-full tw-z-10 tw-mt-2 tw-p-2 tw-min-w-48 tw-rounded tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none"
    >
      <span
        v-if="loading"
        :class="{'tw-mb-1': filterOptions.length > 0}"
        class="text-normal text-muted d-flex justify-content-center"
        >Загрузка...</span
      >
      <span
        v-else-if="searchInput && filterOptions.length === 0"
        class="text-normal text-muted d-flex justify-content-center"
        >Нет значений</span
      >
      <span v-else-if="filterOptions.length === 0" class="text-normal text-muted d-flex justify-content-center"
        >Начните вводить текст для поиска</span
      >
      <div v-if="!tree" class="zem-dropdown-body_scroll">
        <div
          v-for="(option, i) of filterOptions"
          :key="i"
          v-tippy="{content: isObject ? option[titleFieldName] : option, delay: [1500, null], placement: 'top-start'}"
          class="zem-dropdown-item"
          @click="chooseItem(option)"
        >
          {{ isObject ? option[titleFieldName] : option }}
        </div>
      </div>
      <div v-else class="zem-dropdown-body_scroll">
        <zem-tree :numbers="numbers" :tree-data="treeData" @select-element="chooseItem($event.name)" />
      </div>
    </div>
  </div>
</template>

<script>
import ZemCheckbox from './Checkbox'
import ZemInput from '@/components/ui/ZemInput'
import ZemIcon from '@/components/ui/ZemIcon'
import ZemPreloader from '@/components/ui/ZemPreloader'
import ZemTree from '@/components/ui/ZemTree'

export default {
  components: {
    ZemCheckbox,
    ZemInput,
    ZemIcon,
    ZemPreloader,
    ZemTree,
  },
  props: {
    options: {
      type: [Array, Object],
      required: true,
      default: null,
    },
    value: {
      type: [String, Object, Array],
      required: false,
      default: 'Выбрать',
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Выбрать',
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    item: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'object',
    },
    titleFieldName: {
      type: String,
      required: false,
      default: 'title',
    },
    search: {
      type: Boolean,
      required: false,
      default: true,
    },
    tree: {
      type: Boolean,
      required: false,
      default: false,
    },
    numbers: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRight: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selected: null,
      open: false,
      isHidden: true,
      vcoConfig: {
        handler: this.onChangeStatus,
        events: ['dblclick', 'click'],
        isActive: true,
        detectIFrame: true,
        capture: false,
      },
      showPreloader: false,
      searchInput: '',
      timer: null,
      filterOptions: [],
      treeData: [],
    }
  },
  created() {
    this.filterOptions = this.options
    this.selected = this.value
  },
  watch: {
    open() {
      if (this.open) {
        this.$emit('open', true)
        this.showPreloader = true
      } else {
        this.$emit('close')
        if (!this.selected || !this.selected.value) this.onDeleteItems()
      }
      this.filterOptions = this.options
    },
    options() {
      this.filterOptions = this.options
      this.showPreloader = false
    },
    value() {
      this.selected = this.value
    },
  },
  computed: {
    isPlaceholder() {
      return this.isObject
        ? this.value['title'] === this.$t(this.placeholder)
        : this.value === this.$t(this.placeholder)
    },
    isObject() {
      return this.type === 'object'
    },
    inputValue() {
      return this.isObject && this.value ? this.value['title'] : this.value
    },
  },
  methods: {
    async chooseItem(option) {
      this.selected = this.isObject ? option.title : option
      await this.onChangeStatus(false)

      this.$emit('input', option)
      this.$emit('change', option)
      this.$emit('on-select', option)
    },
    onDeleteItems() {
      this.selected = null
      this.$emit('input', null)
      this.$emit('change', null)
      this.$emit('on-delete')
    },
    updateOptions(e, input = false) {
      this.searchInput = e
      if (!input) this.onChangeStatus(false)
      this.showPreloader = true
      clearTimeout(this.timer)
      this.timer = null
      this.timer = setTimeout(() => {
        if (e) {
          this.$emit('on-search', e)
          this.open = true
          this.isHidden = false
        }
        if (this.isObject && e) {
          this.filterOptions = this.options.filter(el => el.title.toLowerCase().indexOf(e.toLowerCase()) !== -1)
        } else if (e) {
          this.filterOptions = this.options.filter(el => el.toLowerCase().indexOf(e.toLowerCase()) !== -1)
        }
        this.showPreloader = false
      }, 500)
    },
    async onChangeStatus(outside = true) {
      if (outside && !this.open) return
      this.isHidden = false
      if (this.open) {
        setTimeout(() => {
          this.isHidden = true
        }, 100)
      }
      setTimeout(() => {
        this.open = !this.open
      }, 0)
    },
  },
}
</script>

<style lang="scss" scoped>
.m-0 {
  .zem-dropdown-head {
    margin: 0;
  }
}

.zem-dropdown {
  width: 100%;
  position: relative;
  margin: 8px 0 0;

  .zem-dropdown-head {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 25px 5px 8px;
    border: 1px solid $color-catskill-grey;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: $color-mine-shaft;
    cursor: pointer;
    user-select: none;
    min-height: 26px;

    &__search {
      position: relative;

      .clear-icon {
        position: absolute;
        right: 25px;
        top: 6px;
        height: 14px !important;
        width: 14px !important;
        cursor: pointer;
      }

      .zem-preloader {
        position: absolute;
        top: 7px;
        height: 12px !important;
        width: 12px !important;
        cursor: pointer;
      }
    }

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__arrow {
      position: absolute;
      right: 10px;
      top: 10px;
      transition: all 0.25s;
      cursor: pointer;
    }

    &__preloader {
      position: absolute;
      right: 26px;
      width: 10px;
    }

    &.open {
      .zem-dropdown-head__arrow {
        transform: rotate(180deg);
      }
    }
  }

  .zem-dropdown-body {
    border: 1px solid $color-catskill-grey;
    border-radius: 4px;
    overflow: hidden;
    max-height: 170px;
    position: absolute;
    background-color: $color-white;
    z-index: 10;
    top: 29px;
    width: 100%;
    padding: 5px 10px;

    &.position-right {
      right: 0;
    }

    &.search {
      .zem-dropdown-body_scroll {
        max-height: 120px;
        margin-top: 30px;
      }
    }

    &_scroll {
      max-height: 130px;
      overflow-x: hidden;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background-color: $color-titan-white;
        border-radius: 100px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-catskill-grey;
        border-radius: 100px;
      }
    }

    &_search {
      position: absolute;
      top: 0;
      width: calc(100% - 20px);

      .zem-icon {
        position: absolute;
        top: 13px;
        right: 8px;
      }

      .zem-preloader {
        position: absolute;
        top: 13px;
        right: 27px;
      }
    }

    &.selectHide {
      max-height: 0;
      padding: 0;
      border: 0 solid #fff;
    }
  }

  .zem-dropdown-item {
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: $color-tundora;
    cursor: pointer;
    padding: 5px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.delete-item:hover {
      span {
        color: $color-oslo-gray !important;
      }
    }

    &:hover {
      color: $color-oslo-gray;
    }
  }

  &.disabled {
    background-color: #fafafb;

    .zem-dropdown-head {
      color: #aaaaaaff;
      cursor: default;

      img {
        display: none;
      }
    }

    .zem-dropdown-body {
      display: none;
    }
  }
}
</style>
