<template>
  <div v-if="show" :class="absolute ? 'position-absolute' : 'position-fixed'" class="zem-modal">
    <div class="zem-modal__card">
      <img :src="require('@/assets/icons/close.svg')" alt="" class="zem-modal__close" @click="onShow" />
      <p class="zem-modal__card__title" v-html="title"></p>
      <slot />
      <div class="modal-footer">
        <ZemLink :color="colorCancel" :loading="loadingCancel" @click="onCancel">{{ titleCancel }}</ZemLink>
        <ZemButton :color="colorSuccess" :loading="loadingSuccess" class="ml-3" @click="onSuccess">
          <img v-if="!loadingSuccess" :src="require('@/assets/icons/check.svg')" alt="" class="mr-1" />{{
            titleSuccess
          }}
        </ZemButton>
      </div>
    </div>
    <div class="zem-modal__background" @click="onShow"></div>
  </div>
</template>

<script>
import ZemButton from '@/components/ui/ZemButton'
import ZemLink from '@/components/ui/ZemLink.vue'

export default {
  components: {
    ZemLink,
    ZemButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    loadingSuccess: {
      type: Boolean,
      default: false,
    },
    loadingCancel: {
      type: Boolean,
      default: false,
    },
    titleSuccess: {
      type: String,
      default: 'Yes',
    },
    titleCancel: {
      type: String,
      default: 'No',
    },
    colorSuccess: {
      type: String,
      default: '',
    },
    colorCancel: {
      type: String,
      default: '',
    },
    absolute: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  methods: {
    onShow() {
      this.$emit('on-change')
    },
    onSuccess() {
      this.$emit('on-success')
    },
    onCancel() {
      this.$emit('on-cancel')
    },
  },
}
</script>

<style lang="scss" scoped>
.zem-modal {
  top: 0;
  left: 0;
  z-index: 999999;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  &.position-fixed {
    position: fixed;
  }

  &.position-absolute {
    position: absolute;
  }

  &__card {
    padding: 1rem;
    background: #fff;
    min-height: 100px;
    max-width: 400px;
    min-width: 280px;
    margin-top: 50px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    z-index: 9999999;
    box-shadow:
      0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0px 6px 16px rgba(0, 0, 0, 0.08),
      0px 9px 28px 8px rgba(0, 0, 0, 0.05);

    &__title {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #3e3e3e;
      margin-right: 30px;
      //text-align: center;
    }
  }

  &__background {
    height: 100%;
    width: 100%;
    background: #0000005e;
  }

  &__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 18px;
    cursor: pointer;
  }
}
</style>
