import $api from "@/helpers/config-axios"

import authHeader from "./auth-header"
import store from "@/store"

class ProfileService {
  async getCompanies() {
    return await $api
      .get("organizations", {
        headers: authHeader(),
      })
      .then(r => {
        store.commit("profile/getCompanies", r.data.data)
      })
  }

  async getCompaniesSearch(data) {
    return await $api
      .get("organizations?search=inn:" + data, {
        headers: authHeader(),
      })
      .then(r => {
        store.commit("profile/getCompaniesSearch", r.data.data)
      })
  }

  getOwnership() {
    return $api
      .get("organization-type-ownerships", {
        headers: authHeader(),
      })
      .then(r => {
        store.commit("profile/getOwnership", r.data.data)
      })
  }

  getOwnershipList() {
    return $api
      .get("organization-type-ownerships?to=list", {
        headers: authHeader(),
      })
  }

  getCities() {
    return $api
      .get("cities", {
        params: {
          limit: "*",
        },
        headers: authHeader(),
      })
      .then(r => {
        const newArr = r.data.data.map(item => {
          return {
            id: item.id,
            title: item.name,
          }
        })
        store.commit("profile/getCities", newArr)
      })
  }

  getCitiesList() {
    return $api
      .get("cities?to=list", {
        headers: authHeader(),
      })
  }

  getCountries() {
    return $api
      .get("countries", {
        headers: authHeader(),
      })
      .then(r => {
        const newArr = r.data.data.map(item => {
          return {
            id: item.id,
            title: item.name,
          }
        })
        store.commit("profile/getCountries", newArr)
      })
  }

  getCountriesList() {
    return $api
      .get("countries?to=list", {
        headers: authHeader(),
      })
  }

  getRegions() {
    return $api
      .get("regions", {
        headers: authHeader(),
      })
      .then(r => {
        const newArr = r.data.data.map(item => {
          return {
            id: item.id,
            title: item.name,
          }
        })
        store.commit("profile/getRegions", newArr)
      })
  }

  getRegionsList() {
    return $api
      .get("regions?to=list", {
        headers: authHeader(),
      })
  }

  createCompany(data) {
    return $api.post("organizations", data, {
      headers: authHeader(),
    })
  }
}

export default new ProfileService()
