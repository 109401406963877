<template>
  <button
    :class="[color, {'zem-button-icon': icon}]"
    :disabled="disabled"
    class="zem-button tw-flex tw-items-center tw-gap-x-2"
    type="button"
    @click="onClickEvent"
  >
    <zem-preloader v-if="loading" />
    <span class="tw-flex tw-items-center tw-gap-x-1.5"><slot /></span>
  </button>
</template>

<script>
import ZemPreloader from '@/components/ui/ZemPreloader'

export default {
  name: 'ZemButton',

  components: {
    ZemPreloader,
  },

  props: {
    color: {
      type: [String],
      default: '',
    },
    isPlusMode: {
      type: [Boolean],
      default: false,
    },
    icon: {
      type: [Boolean],
      default: false,
    },
    disabled: {
      type: [Boolean],
      default: false,
    },
    loading: {
      type: [Boolean],
      default: false,
    },
  },
  methods: {
    onClickEvent() {
      this.$emit('click')
    },
  },
}
</script>

<style lang="scss" scoped>
.zem-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  background: $color-accent;
  border: 1px solid $color-accent;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  color: $color-white;
  cursor: pointer;
  transition-duration: 0.2s;

  &.zem-button-icon {
    padding: 5px 6px;
  }

  &--plus {
    width: 40px;
    height: 40px;
  }

  &--white {
    background: $color-catskill-white;
    border: 1px solid $color-catskill-white;
    color: $color-gull-gray;

    &:hover {
      background: $color-riptide;
      color: $color-white;
    }
  }

  &:hover {
    opacity: 0.7;
    color: $color-white;
  }

  &.danger {
    background: $color-red;
    border-color: $color-red;
  }

  &.grey {
    background: $color-catskill-grey;
    border-color: $color-catskill-grey;
    color: $color-oslo-gray;
  }
}

.zem-button:disabled,
.zem-button[disabled] {
  border: 1px solid #7bcdcd;
  background: #7bcdcd;
  cursor: default;
}
</style>
