var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-2"},[_c('UserCard',{attrs:{"info":_vm.itemInfo,"status-edit":_vm.editProfile},on:{"save":_vm.updateProfile,"on-edit":function($event){_vm.editProfile = !_vm.editProfile},"on-cancel":_vm.cancelProfile}}),_c('validation-observer',{ref:"profileForm"},_vm._l((_vm.fields),function(item,idx){return _c('zem-collapse',{key:idx,attrs:{"title":_vm.$t('Contacts')}},[_c('div',{staticClass:"zem-collapse-table rows"},_vm._l((item.data),function(field,f_idx){return _c('div',{key:f_idx,staticClass:"zem-collapse-table__row"},[_c('div',{staticClass:"zem-collapse-table__column title"},[_vm._v(" "+_vm._s(field.name)),(_vm.editProfile)?_c('span',{staticClass:"required-field"},[_vm._v("*")]):_vm._e()]),(!_vm.editProfile)?_c('div',{staticClass:"zem-collapse-table__column text"},[_vm._v(" "+_vm._s(field.value)+" ")]):(_vm.editProfile && field.type === 'phone')?_c('div',{staticClass:"zem-collapse-table__column text"},[_c('ValidationProvider',{staticClass:"w-100",attrs:{"name":field.name,"rules":{
                required: true,
                regex: `^[\\+]?[7]?[8]?\\s[(][0-9]{3}[)]\\s[0-9]{3}[-\\s\\.]?[0-9]{2}[-\\s\\.]?[0-9]{2}$`,
              }},scopedSlots:_vm._u([{key:"default",fn:function({errors}){return [_c('ZemInput',{staticClass:"m-0 mt-0",attrs:{"errors":_vm.errorsBackend,"error-title":"client_phone","mask":"phone","placeholder":"+71234567890","type":"tel"},on:{"input":function($event){_vm.errorsBackend = []},"update-phone":function($event){field.value = $event}},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(errors.length !== 0),expression:"errors.length !== 0"}],staticClass:"details-form__data-block__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1):(_vm.editProfile && field.type === 'email')?_c('div',{staticClass:"zem-collapse-table__column text"},[_c('ValidationProvider',{staticClass:"w-100",attrs:{"name":field.name,"rules":{
                required: true,
                email: true,
              }},scopedSlots:_vm._u([{key:"default",fn:function({errors}){return [_c('ZemInput',{staticClass:"m-0",attrs:{"placeholder":field.name,"isCompact":"","type":"email"},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(errors.length !== 0),expression:"errors.length !== 0"}],staticClass:"details-form__data-block__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1):_c('div',{staticClass:"zem-collapse-table__column text"},[_c('ZemInput',{staticClass:"m-0",attrs:{"placeholder":field.name,"isCompact":"","type":"text"},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})],1)])}),0)])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }