export const orders = {
  namespaced: true,

  state: {
    tree: [],
    breadCrumbs: [],
    isGetAllOrdersSuccess: false,
    deleteAllOrdersSuccess: false,
    section: {
      title: '',
      id: '',
    },
    orders: [],
    selectedOrders: [],
    ordersPagination: {
      custom: {
        total: 0,
      },
      pagination: {
        current_page: 1,
        total_pages: 1,
        total: 0,
      }
    },
    address: [],
    regions: [
      {
        title: null,
      },
    ],
    onlyRegions: [
      {
        title: null,
      },
    ],
    statuses: [],
    partnerStatuses: [],
    selectedItemsForDelete: [],
    paginationPage: 1,
    filter: {},
    orderBy: null,
    sortedBy: null,
    search: '',
    searchCount: 0,
    statusOrders: false,
    loading: {
      takeInWork: false,
      takeOrdersInWork: false,
      takeAllOrdersInWork: false,
    },
    isArchive: false,
    activeMultipleChoice: false,
  },

  mutations: {
    getAllOrders(state, orders) {
      state.isGetAllOrdersSuccess = true
      state.orders = orders.data
      if (orders.meta) state.ordersPagination = orders.meta
    },
    updateElements(state, data) {
      state.orders = data
    },
    getSelectedOrders(state, orders) {
      state.selectedOrders = orders.data
    },
    getAllOrdersFailure(state) {
      state.isGetAllOrdersSuccess = false
      state.orders = null
      state.ordersPagination = {
        custom: {
          total: 0,
          settings: {
            value: {},
          },
        },
        pagination: {
          current_page: 1,
          total_pages: 1,
          total: 0,
        },
      }
    },
    changePagination(state) {
      state.ordersPagination = state
    },
    deleteAllOrders(state, data) {
      state.deleteAllOrdersSuccess = data
    },
    itemsForDelete(state, data) {
      state.selectedItemsForDelete = data
    },
    selectedSection(state, payload) {
      state.section = payload
    },
    getAddress(state, data) {
      state.address = data.data.suggestions
    },
    getStatuses(state, data) {
      state.statuses = data
    },
    getPartnerStatuses(state, data) {
      state.partnerStatuses = data
    },
    filterMain(state, data) {
      state.filter['main'] = data
    },
    changePage(state, data) {
      state.paginationPage = data
    },
    sorting(state, data) {
      state.sortedBy = data
    },
    ordering(state, data) {
      state.orderBy = data
    },
    getSearch(state, data) {
      state.search = data
    },
    getSearchCount(state, data) {
      state.searchCount = data
    },
    updateStatusOrders(state, data) {
      state.statusOrders = data
    },
    changeIsArchive(state, data) {
      state.isArchive = data
    },
    changeCurrentPage(state) {
      state.paginationPage = 1
    },
    changeTakeInWorkPreloader(state, data) {
      state.loading.takeInWork = data
    },
    changeTakeOrdersInWorkPreloader(state, data) {
      state.loading.takeOrdersInWork = data
    },
    changeTakeAllOrdersInWorkPreloader(state, data) {
      state.loading.takeAllOrdersInWork = data
    },
    breadCrumbs(state, data) {
      state.breadCrumbs = data
    },
    updateTree(state, payload) {
      state.tree = payload
    },
    clearState(state) {
      state = {
        isGetAllOrdersSuccess: false,
        deleteAllOrdersSuccess: false,
        orders: [],
        selectedOrders: [],
        ordersPagination: {
          custom: {
            total: 0,
            settings: {
              value: {},
            },
          },
          pagination: {
            current_page: 1,
            total_pages: 1,
            total: 0,
          },
        },
        address: [],
        regions: [
          {
            title: null,
          },
        ],
        onlyRegions: [
          {
            title: null,
          },
        ],
        statuses: [],
        partnerStatuses: [],
        selectedItemsForDelete: [],
        paginationPage: 1,
        filter: {},
        orderBy: null,
        sortedBy: null,
        search: '',
        searchCount: 0,
        statusOrders: false,
        loading: {
          takeInWork: false,
          takeOrdersInWork: false,
          takeAllOrdersInWork: false,
        },
      }
    },
  },

  actions: {},
}
