<template>
    <li>
        <div
            class="d-flex align-center">
            <img
                v-if="isFolder"
                :src="require('@/assets/icons/select_wood.svg')"
                alt=""
                @click="toggle"
                class="zem-select-wood"
                :class="{ 'rotate': isOpen }"
            >
            <span @click="selectItem(item)">{{ numbers ? `${indexItem}. ` : '' }}{{ item.name }}</span>
        </div>
        <ul v-show="isOpen" v-if="isFolder">
            <zem-tree-item
                class="item"
                v-for="(child, index) in item.children"
                :key="index"
                :item="child"
                :index-item="index + 1"
                :numbers="numbers"
                @select-element="selectItem"
            ></zem-tree-item>
            <!--            <li class="add" @click="$emit('add-item', item)">+</li>-->
        </ul>
    </li>
</template>

<script>
export default {
    name: 'ZemTreeItem',
    props: {
        item: [Object, Array],
        indexItem: Number,
        numbers: Boolean
    },
    data: function () {
        return {
            isOpen: false
        };
    },
    computed: {
        isFolder() {
            return this.item.children && this.item.children.length;
        }
    },
    methods: {
        toggle() {
            if (this.isFolder) {
                this.isOpen = !this.isOpen;
            }
        },
        selectItem(el) {
            this.$emit("select-element", el);
        }
    }
}
</script>

<style scoped lang="scss">

</style>
