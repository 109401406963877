import $api from '@/helpers/config-axios'

import authHeader from './auth-header'
import store from '@/store'

class UserService {
  async getUserProfile() {
    return await $api.get(
      'user/profile?include=roles,human.organizations,human.priority_organization',
      {headers: authHeader()}
    )
  }

  getUserProfileCheck() {
    return $api.get(
      'user/profile',
      {headers: authHeader()}
    )
  }

  async getAllHumans() {
    return await $api
      .get('humans', {
        headers: authHeader(),
      })
      .then(r => {
        store.commit('user/getAllHumansSuccess', r.data.data)
      })
  }

  async updateProfile(data) {
    return await $api.patch('human/profile', data, {
      headers: authHeader(),
    })
  }
}

export default new UserService()
