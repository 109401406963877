<template>
  <div class="redirect-page">
    <img :src="require('@/assets/logo.png')" alt="" />
    <h3>ERP | Управление ресурсами компании</h3>
  </div>
</template>

<script>
export default {
  name: 'Redirect',
  computed: {
    role() {
      return this.$store.state.user.role
    },
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
.redirect-page {
  width: calc(100% - 80px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #3e3e3e;

  img {
    height: 60px;
    margin-bottom: 20px;
  }

  h3 {
    text-align: center;
  }
}

@media screen and (max-width: 1024px) {
  .redirect-page {
    padding: 0 20px;
    width: 100%;
  }
}
</style>