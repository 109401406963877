import store from '@/store'
import ability from '@/libs/acl/ability'

const handlerRoles = userRoles => {
  let userPermissions = []
  userRoles.forEach(role => {
    role.permissions.data.forEach(permission => {
      userPermissions.push({
        action: 'edit',
        subject: `${permission.name}`,
      })
    })
  })
  ability.update(userPermissions)
  store.commit('user/setAbility', userPermissions)
}

export default handlerRoles
