export const profile = {
  namespaced: true,

  state: {
    show: false,
    showSyncAmo: false,
    read: false,
    settingsValue: 'profile',
    companies: [],
    companiesSearch: [],
    organization: [],
    ownership: [],
    cities: [],
    countries: [],
    regions: [],
  },

  actions: {},

  mutations: {
    showProfile(state, status) {
      state.show = status
    },
    showSyncAmo(state, status) {
      state.showSyncAmo = status
    },
    updateSettings(state, type) {
      state.settingsValue = type
    },
    readProfile(state, status) {
      state.read = status
    },
    getCompanies(state, status) {
      state.companies = status
    },
    getCompaniesSearch(state, data) {
      state.companiesSearch = data
    },
    getOwnership(state, data) {
      state.ownership = data
    },
    getCities(state, data) {
      state.cities = data
    },
    getCountries(state, data) {
      state.countries = data
    },
    getRegions(state, data) {
      state.regions = data
    },
    getOrganization(state, data) {
      state.organization = data
    },
  },
}
