<template>
  <div class="collapse-card">
    <div class="collapse-card__header" @click="onHeaderClick">
      <img
        :class="{rotate: !isBodyVisible}"
        :src="require('@/assets/icons/dropdown-arrow-primary.svg')"
        alt=""
        class="collapse-card__icon"
      />
      {{ fields['title'] }}
    </div>
    <div v-if="isBodyVisible" class="collapse-card__body">
      <div v-for="(item, idx) in fields['data']" :key="idx">
        <div class="collapse-card__row">
          <div v-if="!item.new" :style="{height: '100%'}" class="collapse-card__column title">
            {{ item.name }}
          </div>
          <div v-else class="collapse-card__column title">
            <ZemDropdownList
              :options="selectItems"
              :placeholder="'Выбрать'"
              :value="activeSelect.title"
              class="px-0"
              item="name"
              @input="activeSelect = $event"
            />
          </div>
          <template v-if="!edit && item.name === 'Роль'">
            <div class="collapse-card__column collapse-card__column--second text">
              {{ item.value.map(role => role.title).join(', ') }}
            </div>
          </template>
          <template v-else-if="!edit">
            <div class="collapse-card__column collapse-card__column--second text">
              {{
                ['Страна', 'Регион', 'Город', 'Тип организации', 'Пользователь amoCRM'].includes(item.name)
                  ? item.value.title
                  : item.value
              }}
            </div>
          </template>
          <template v-else>
            <!-- Пользователь amoCRM -->
            <div v-if="item.type === 'list'" class="collapse-card__column collapse-card__column--second text">
              <ZemDropdownList
                :options="$store.state.users.usersAmoCRM"
                :value="item['value']"
                class="select m-0"
                default="Пользователь amoCRM"
                placeholder="Выбрать"
                @input="item.value = $event"
              />
            </div>

            <div
              v-else-if="item.name === 'Название' && item.read && lengthOrganizations > 1"
              class="collapse-card__column collapse-card__column--second text"
            >
              <ZemDropdownList
                :default="'Название'"
                :options="$store.state.user.profile.human.data.organizations.data"
                :placeholder="'Название'"
                :value="item['value']"
                class="select mt-0"
                item="value"
                @input="updateOrganization(item, $event)"
              />
            </div>
            <div
              v-else-if="item.name === 'Роль' && item.read"
              class="collapse-card__column collapse-card__column--second text"
            >
              <ZemMultiSelect
                :default="'Роль'"
                :disabled="!$can('edit', 'manage-admins-access')"
                :options="$store.state.other.roles"
                :placeholder="'Роль'"
                :value="item['value']"
                class="select mt-0"
                type="object"
                @input="item.value = $event"
              />
            </div>
            <div v-else-if="item.name === 'Страна'" class="collapse-card__column collapse-card__column--second text">
              <ZemDropdownList
                :default="'Страна'"
                :options="$store.state.profile.countries"
                :value="item['value'].title || item['value'].name"
                class="select m-0"
                item="name"
                placeholder="Выбрать"
                @input="item.value = $event"
              />
            </div>

            <!-- Регион -->
            <div v-else-if="item.name === 'Регион'" class="collapse-card__column collapse-card__column--second text">
              <ZemDropdownList
                :default="'Регион'"
                :options="$store.state.profile.regions"
                :value="item['value'].title || item['value'].name"
                class="select m-0"
                item="name"
                placeholder="Выбрать"
                @input="item.value = $event"
              />
            </div>

            <!-- Город -->
            <div v-else-if="item.name === 'Город'" class="collapse-card__column collapse-card__column--second text">
              <ZemDropdownList
                :default="'Город'"
                :options="$store.state.profile.cities"
                :value="item['value'].title || item['value'].name"
                class="select m-0"
                item="name"
                placeholder="Выбрать"
                @input="item.value = $event"
              />
            </div>

            <!-- Тип организации -->
            <div
              v-else-if="item.name === 'Тип организации'"
              class="collapse-card__column collapse-card__column--second text"
            >
              <ZemDropdownList
                :default="'Тип организации'"
                :options="$store.state.profile.ownership"
                :value="item.value"
                class="select m-0"
                item="name"
                placeholder="Выбрать"
                @input="item.value = $event"
              />
            </div>

            <div v-else-if="fields['title'] !== 'Организация' && item.read" class="collapse-card__input">
              <ZemInput
                v-model="item.value"
                :disabled="item.name === 'Email'"
                :mask="item.type"
                :placeholder="item.name"
                :type="item.type"
                class="m-0"
                isCompact
              />
            </div>

            <div v-else class="collapse-card__column collapse-card__column--second text">
              {{ item.value === undefined ? '' : item.value }}
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZemInput from './ui/ZemInput'
import ZemSelect from '@/components/ui/Select'
import ZemButton from '@/components/ui/ZemButton'
import ZemDropdownList from './ui/ZemDropdownList'
import ZemMultiSelect from '@/components/ui/ZemMultiSelect.vue'

export default {
  name: 'CollapseCard',

  components: {
    ZemMultiSelect,
    ZemInput,
    ZemSelect,
    ZemButton,
    ZemDropdownList,
  },

  props: {
    isOpenedDefault: {
      type: [Boolean],
      default: true,
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: '',
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentFields: {},
      isBodyVisible: true,
      selectItems: [{title: 'Telegram'}, {title: 'VK'}],
      activeSelect: {
        title: 'Выбрать',
      },
    }
  },

  computed: {
    getNewFields() {
      return this.currentFields.possibleToCreate
    },
    lengthOrganizations() {
      return this.$store.state.user.profile.human.data.organizations.data.length
    },
  },

  watch: {
    currentFields() {
      this.$emit('update-data', this.currentFields)
    },
  },

  mounted() {
    this.currentFields = this.fields
    this.currentFields.data.forEach(f => {
      f.isEdit = false
    })
    this.isBodyVisible = this.isOpenedDefault
    this.$emit('collapse', this.isBodyVisible)
  },

  methods: {
    onHeaderClick() {
      this.isBodyVisible = !this.isBodyVisible
      this.$emit('collapse', this.isBodyVisible)
    },
    middleware(event) {
      return event.target.className !== 'modal'
    },
    updateOrganization(item, event) {
      item['value'] = event.title
      this.fields.data[1].value = event.inn
      this.fields.data[1].organization_id = event.id
    },
  },
}
</script>

<style lang="scss">
.collapse-card {
  &__body {
    .zem-dropdown-head {
      margin: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
.collapse-card {
  width: 100%;
  margin-top: 12px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;

  &.w-520 {
    width: 520px;
  }

  &__icon {
    display: flex;
    justify-content: flex-end;
    margin-right: 15px;
    font-size: 22px;
    color: $color-accent;
  }

  &__input {
    display: flex;
    flex: 1;
  }

  &__button {
    padding: 0;
    margin: 0 0 0 5px;
    width: 30px;
    height: 17px;
    min-width: 30px;
  }

  &__icon {
    margin-right: 8px;
    transform: rotateZ(180deg);

    &.rotate {
      transform: rotateZ(0deg);
    }
  }

  &__header {
    background: $color-white;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: $color-mine-shaft;
    padding-left: 12px;
    user-select: none;

    &:hover {
      //background: $color-mystic;
      cursor: pointer;
    }
  }

  &__body {
    background: $color-white;
    display: block;
    padding: 12px;

    .zem-dropdown-head {
      margin: 0;
    }
  }

  &__column {
    flex: 1;

    &--second {
      flex: 1;
      cursor: pointer;
    }

    &--select {
      width: 244px;
      justify-content: space-between !important;
      align-items: center !important;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    align-self: flex-start;
    align-content: inherit;
    text-align: left;
    justify-content: flex-start;
    margin-bottom: 8px;
  }
}

.title {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: $color-gull-gray;
  margin-right: 10px;
}

.colored {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: $color-accent;

  &--link {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.text {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: $color-mine-shaft;
}

@media screen and (max-width: 768px) {
  .collapse-card {
    width: 100%;
  }
}
</style>
