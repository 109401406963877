export const quickCalculation = {
  namespaced: true,

  state: {
    drop: false,
    selected: null,
    section: {
      title: '',
      id: '',
    },
    positionCollectionId: [],
    elements: [],
    directoriesTree: [],
    isGetAllPeoplesSuccess: false,
    productsPagination: {
      custom: {
        total: null,
      },
      pagination: {
        current_page: null,
        total_pages: null,
        total: null,
      },
    },
    selectedItemsForDelete: [],
    selectedProducts: [],
    paginationPage: 1,
    filter: {},
    orderBy: null,
    sortedBy: null,
    search: '',
    isActive: null,
    isArchive: false,
    searchCount: 0,
    breadCrumbs: [],
    showTreeItems: [],
    activeDragAndDrop: false,
    activeMultipleChoice: true,
  },

  mutations: {
    dropChange(state, status) {
      state.drop = status
    },
    updateElements(state, payload) {
      state.elements = payload.data
    },
    updatePagination(state, payload) {
      state.productsPagination = payload.meta
    },
    updateDirectoriesTree(state, payload) {
      state.directoriesTree = payload
    },
    selectedItem(state, payload) {
      state.selected = payload
    },
    selectedSection(state, payload) {
      state.section = payload
    },
    selectedPositionCollection(state, payload) {
      state.positionCollectionId = payload
    },
    sorting(state, data) {
      state.sortedBy = data
    },
    ordering(state, data) {
      state.orderBy = data
    },
    filterMain(state, data) {
      state.filter['main'] = data
    },
    itemsForDelete(state, data) {
      state.selectedItemsForDelete = data
    },
    getSearch(state, data) {
      state.search = data
    },
    changePage(state, data) {
      state.paginationPage = data
    },
    changeIsActive(state, data) {
      state.isActive = data
    },
    changeIsArchive(state, data) {
      state.isArchive = data
    },
    getSearchCount(state, data) {
      state.searchCount = data
    },
    breadCrumbs(state, data) {
      state.breadCrumbs = data
    },
    updateTreeItems(state, data) {
      if (state.showTreeItems.indexOf(data) === -1) {
        state.showTreeItems.push(data)
      } else {
        state.showTreeItems.splice(state.showTreeItems.indexOf(data), 1)
      }
    },
    updateDragAndDrop(state) {
      state.activeDragAndDrop = !state.activeDragAndDrop
    },
    updateMultipleChoice(state) {
      state.activeMultipleChoice = !state.activeMultipleChoice
    },
  },
}
