export const users = {
  namespaced: true,

  state: {
    users: [],
    usersAmoCRM: [],
    selectedUsers: [],
    isGetAllUsersSuccess: false,
    usersPagination: {
      custom: {
        total: null,
      },
      pagination: {
        current_page: null,
        total_pages: null,
        total: null,
      },
    },
    selectedItemsForDelete: [],
    paginationPage: 1,
    filter: {},
    orderBy: null,
    sortedBy: null,
    search: '',
    isActive: null,
    isArchive: false,
    searchCount: 0,
  },

  mutations: {
    getAllUsers(state, users) {
      state.isGetAllUsersSuccess = true
      state.users = users.data
      state.usersPagination = users.meta
    },
    getUsersAmoCRM(state, users) {
      state.usersAmoCRM = users.data
    },
    changePagination(state) {
      state.usersPagination = state
    },
    getSelectedUsers(state, users) {
      state.selectedUsers = users.data
    },
    sorting(state, data) {
      state.sortedBy = data
    },
    ordering(state, data) {
      state.orderBy = data
    },
    filterMain(state, data) {
      state.filter['main'] = data
    },
    itemsForDelete(state, data) {
      state.selectedItemsForDelete = data
    },
    getSearch(state, data) {
      state.search = data
    },
    changePage(state, data) {
      state.paginationPage = data
    },
    changeIsActive(state, data) {
      state.isActive = data
    },
    changeIsArchive(state, data) {
      state.isArchive = data
    },
    getSearchCount(state, data) {
      state.searchCount = data
    },
  },

  actions: {},
}
