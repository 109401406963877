export const estimates = {
  namespaced: true,

  state: {
    isGetAllBrandsSuccess: false,
    deleteAllBrandsSuccess: false,
    estimatesItems: [],
    selectedEstimates: {},
    estimatesPagination: {
      custom: {
        total: 0,
        settings: {
          value: {},
        },
      },
      pagination: {
        current_page: 1,
        total_pages: 1,
        total: 0,
      },
    },
    selectedItemsForDelete: [],
    paginationPage: 1,
    filter: {},
    brandBy: null,
    sortedBy: null,
    search: '',
    orderNumber: '',
    status: '',
    searchCount: 0,
    isActive: null,
    isArchive: false,
    statusBrands: false,
    loading: {
      takeInWork: false,
      takeBrandsInWork: false,
      takeAllBrandsInWork: false,
    },
  },

  mutations: {
    getAllEstimatesItems(state, data) {
      state.estimatesItems = data.data.map(el => {
        let new_el = el
        if (el.hasOwnProperty('brand')) new_el['brand'] = el.brand.data
        return new_el
      })
      state.estimatesPagination = data.meta
    },
    changePagination(state, data) {
      state.estimatesPagination = data.meta
    },
    sorting(state, data) {
      state.sortedBy = data
    },
    ordering(state, data) {
      state.orderBy = data
    },
    filterMain(state, data) {
      state.filter['main'] = data
    },
    itemsForDelete(state, data) {
      state.selectedItemsForDelete = data
    },
    resetSelectedRows(state) {
      state.selectedItemsForDelete = []
    },
    getSearch(state, data) {
      state.search = data
    },
    getOrderNumber(state, data) {
      state.orderNumber = data
    },
    getStatus(state, data) {
      state.status = data
    },
    changePage(state, data) {
      state.paginationPage = data
    },
    changeIsActive(state, data) {
      state.isActive = data
    },
    changeIsArchive(state, data) {
      state.isArchive = data
    },
    getSearchCount(state, data) {
      state.searchCount = data
    },
    changeSelectedEstimates(state, data) {
      state.selectedEstimates = data
    },
  },

  actions: {},
}
