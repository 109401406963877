<template>
  <div class="user-card">
    <div class="user-card__container">
      <div v-if="info['type'] === 'people'" class="user-card__column-image">
        <div class="user-card__image">
          <img :src="info['img']" alt="" />
        </div>
      </div>

      <div class="user-card__column-info">
        <h4>{{ info['name'] }}</h4>
        <div class="user-card__info-container">
          <div class="user-card__id">
            <span class="user-card__label"></span>
            ID {{ info['id'] }}
          </div>
        </div>
        <div v-if="!closeAcc([3])" class="block-id">
          <span class="block-id__label second"></span>
          ID {{ info['user_id'] || info['human_id'] || info['id2'] }}
        </div>
      </div>

      <div class="user-card__column-status tw-gap-1">
        <template v-if="!statusEdit && isEdit">
          <zem-button class="md:tw-hidden" @click="onRead">
            <img :src="require('@/assets/icons/edit-o.svg')" alt="" height="16" width="16" />
          </zem-button>
          <zem-link v-tippy="{content: 'Редактировать'}" class="max-md:tw-hidden" @click="onRead">
            <zem-icon name="edit" size="15" />
          </zem-link>
          <img
            :src="require('@/assets/icons/close.svg')"
            alt=""
            class="max-md:tw-hidden cursor-pointer"
            height="22"
            width="22"
            @click="closeSidebar"
          />
          <zem-button class="md:tw-hidden ml-1" @click="closeSidebar">
            <img :src="require('@/assets/icons/close-o.svg')" alt="" height="16" width="16" />
          </zem-button>
        </template>
        <template v-else>
          <zem-button class="md:tw-hidden mr-1" @click="onCancel">
            <img :src="require('@/assets/icons/close-o.svg')" alt="" />
          </zem-button>
          <zem-button class="md:tw-hidden" @click="onSave">
            <img :src="require('@/assets/icons/check.svg')" alt="" />
          </zem-button>
          <zem-link class="mr-2 max-md:tw-hidden" @click="onCancel">
            {{ $t('Cancel') }}
          </zem-link>
          <zem-button v-if="statusEdit" class="max-md:tw-hidden" @click="onSave">
            {{ $t('Save') }}
          </zem-button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ZemLink from '@/components/ui/ZemLink'
import ZemButton from '@/components/ui/ZemButton'
import ZemIcon from '@/components/ui/ZemIcon'
import {closeAcc} from '@/assets/scripts/scripts'

export default {
  name: 'UserCard',
  components: {
    ZemLink,
    ZemButton,
    ZemIcon,
  },

  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    statusEdit: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    closeAcc,
    onRead() {
      this.$emit('on-edit')
    },
    onSave() {
      this.$emit('save')
    },
    onCancel() {
      this.$emit('on-cancel')
    },
    closeSidebar() {
      this.$emit('close-sidebar')
    },
  },
}
</script>

<style lang="scss" scoped>
.user-card {
  display: block;
  margin-bottom: 16px;

  &__container {
    background-color: $color-white;
    display: flex;
    align-items: flex-start;
    text-align: left;
    padding: 12px 15px;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    width: 100%;
  }

  &__image {
    img {
      width: 40px;
      height: 40px;
      border-radius: 2px;
      object-fit: cover;
      margin-right: 12px;
    }
  }

  &__id {
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: $color-emperor;
    display: flex;
    align-items: center;
  }

  &__label {
    width: 6px;
    height: 6px;
    background: #2eb67d;
    border-radius: 100px;
    margin-right: 5px;
  }

  &__column-info {
    h4 {
      font-weight: 700;
      font-size: 18px;
      line-height: 18px;
      color: $color-mine-shaft;
      margin: 0 0 8px 0;
    }
  }

  &__info-container {
    display: flex;
    justify-content: space-between;
  }

  &__row-container {
    flex: 2;
  }

  &__info-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  &__info-title {
    flex: 1;
  }

  &__info-value {
    flex: 1;
  }

  &__info-new {
    flex: 1;
  }

  &__column-status {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-top: 3px;
  }

  &__link {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.title {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: $color-gull-gray;
}

.colored {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: $color-accent;
}

.text {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: $color-mine-shaft;
}
</style>