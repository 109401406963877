import Vue from 'vue'
import Vuex from 'vuex'
import {auth} from './auth.module'
import {user} from './user.module'
import {sidebars} from './sidebars.module'
import {profile} from '@/store/profile.module'
import {peoples} from '@/store/peoples.module'
import {companies} from '@/store/companies.module'
import {filterPeoplesAndCompany} from '@/store/filterPeoplesAndCompany.module'
import {users} from '@/store/users.module'
import {other} from '@/store/other.module'
import {products} from '@/store/products.module'
import {directory} from '@/store/directory.module'
import {quickCalculation} from '@/store/quickCalculation.module'
import {estimates} from '@/store/estimates.module'
import {roles} from '@/store/roles.module'
import {orders} from '@/store/orders.module'
import {objects} from '@/store/objects.module'
import {exchange} from '@/store/exchange.module'
import {logs} from '@/store/logs.module'
import {history} from '@/store/history.module'
import {events} from '@/store/events.module'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    user,
    sidebars,
    profile,
    peoples,
    companies,
    filterPeoplesAndCompany,
    users,
    other,
    products,
    directory,
    quickCalculation,
    estimates,
    roles,
    orders,
    objects,
    exchange,
    logs,
    history,
    events,
  },
})
