import $api from '@/helpers/config-axios'

import authHeader from './auth-header'
import store from '@/store'
import {eventBus} from '@/main'

class DirectoryService {
  async getDirectory(path, includes) {
    store.commit('other/onChangeLoadingItems', true)
    const {orderBy, sortedBy, search, paginationPage} = store.state.directory
    let parameters = {
      page: paginationPage,
      include: includes,
      orderBy: orderBy,
      sortedBy: sortedBy,
    }
    if (search) {
      parameters.search = search
    }
    return await $api
      .get(path, {
        params: parameters,
        headers: authHeader(),
      })
  }

  async editDirectory(id, path, data) {
    return await $api.patch(path + '/' + id, data, {
      headers: authHeader(),
    })
  }

  async createDirectory(path, data) {
    return await $api.post(path, data, {
      headers: authHeader(),
    })
  }

  async deleteDirectory(path) {
    return await $api.delete(`${path}/${store.state.directory.selectedItemsForDelete[0]}`, {
      headers: authHeader(),
    })
  }

  async deleteDirectories(path) {
    return await $api.delete(`${path}`, {
      headers: authHeader(),
      data: {
        ids: store.state.directory.selectedItemsForDelete,
      },
    })
  }

  archiveItems() {
    return $api.post(
      'orders/restore',
      {
        ids: store.state.orders.selectedItemsForDelete,
      },
      {
        headers: authHeader(),
      }
    )
  }
}

export default new DirectoryService()
