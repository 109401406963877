export const exchange = {
  namespaced: true,

  state: {
    isGetAllItemsSuccess: false,
    deleteAllItemsSuccess: false,
    elements: [],
    selectedElements: [],
    exchangePagination: {
      custom: {
        total: 0,
      },
      pagination: {
        current_page: 1,
        total_pages: 1,
        total: 0,
      }
    },
    address: [],
    regions: [
      {
        title: null,
      },
    ],
    onlyRegions: [
      {
        title: null,
      },
    ],
    statuses: [],
    partnerStatuses: [],
    selectedItemsForDelete: [],
    paginationPage: 1,
    filter: {},
    orderBy: null,
    sortedBy: null,
    search: '',
    searchCount: 0,
    statusExchange: false,
    loading: {
      takeInWork: false,
      takeExchangeInWork: false,
      takeAllExchangeInWork: false,
    },
    isArchive: false,
  },

  mutations: {
    getAllExchange(state, exchange) {
      state.isGetAllItemsSuccess = true
      state.elements = exchange.data
      if (exchange.meta) state.exchangePagination = exchange.meta
    },
    getSelectedExchange(state, exchange) {
      state.selectedElements = exchange.data
    },
    getAllExchangeFailure(state) {
      state.isGetAllItemsSuccess = false
      state.elements = null
      state.exchangePagination = {
        custom: {
          total: 0,
          settings: {
            value: {},
          },
        },
        pagination: {
          current_page: 1,
          total_pages: 1,
          total: 0,
        },
      }
    },
    changePagination(state) {
      state.exchangePagination = state
    },
    deleteAllExchange(state, data) {
      state.deleteAllItemsSuccess = data
    },
    itemsForDelete(state, data) {
      state.selectedItemsForDelete = data
    },
    getAddress(state, data) {
      state.address = data.data.suggestions
    },
    getStatuses(state, data) {
      state.statuses = data
    },
    getPartnerStatuses(state, data) {
      state.partnerStatuses = data
    },
    filterMain(state, data) {
      state.filter['main'] = data
    },
    changePage(state, data) {
      state.paginationPage = data
    },
    sorting(state, data) {
      state.sortedBy = data
    },
    ordering(state, data) {
      state.orderBy = data
    },
    getSearch(state, data) {
      state.search = data
    },
    getSearchCount(state, data) {
      state.searchCount = data
    },
    updateStatusExchange(state, data) {
      state.statusExchange = data
    },
    changeIsArchive(state, data) {
      state.isArchive = data
    },
    changeCurrentPage(state) {
      state.paginationPage = 1
    },
    changeTakeInWorkPreloader(state, data) {
      state.loading.takeInWork = data
    },
    changeTakeExchangeInWorkPreloader(state, data) {
      state.loading.takeExchangeInWork = data
    },
    changeTakeAllExchangeInWorkPreloader(state, data) {
      state.loading.takeAllExchangeInWork = data
    },
    clearState(state) {
      state = {
        isGetAllItemsSuccess: false,
        deleteAllExchangeSuccess: false,
        elements: [],
        selectedElements: [],
        exchangePagination: {
          custom: {
            total: 0,
            settings: {
              value: {},
            },
          },
          pagination: {
            current_page: 1,
            total_pages: 1,
            total: 0,
          },
        },
        address: [],
        regions: [
          {
            title: null,
          },
        ],
        onlyRegions: [
          {
            title: null,
          },
        ],
        statuses: [],
        partnerStatuses: [],
        selectedItemsForDelete: [],
        paginationPage: 1,
        filter: {},
        orderBy: null,
        sortedBy: null,
        search: '',
        searchCount: 0,
        statusExchange: false,
        loading: {
          takeInWork: false,
          takeExchangeInWork: false,
          takeAllExchangeInWork: false,
        },
      }
    },
  },

  actions: {},
}
