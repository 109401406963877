<template>
  <div class="settings">
    <template v-if="menuType === 'profile'">
      <div class="settings__main-container">
        <h1 class="settings__label">Профиль пользователя</h1>
        <img
          v-tippy="{content: 'Сменить пароль'}"
          :src="require('@/assets/icons/tag.svg')"
          alt="#"
          class="settings__tag"
          @click="showChangePasswordModal = true"
        />
        <img
          v-tippy="{content: 'Выйти из системы'}"
          :src="require('@/assets/icons/log-out.svg')"
          alt="#"
          class="settings__logout"
          @click="showModal = true"
        />
      </div>
      <UserCard
        :info="itemInfo"
        :status-edit="editProfile"
        @save="updateProfile"
        @on-edit="editProfile = !editProfile"
        @on-cancel="cancelProfile"
      />
      <validation-observer ref="profileForm">
        <zem-collapse v-for="(item, idx) in fields" :key="idx" :title="$t('Contacts') || ''">
          <div class="zem-collapse-table rows">
            <div v-for="(field, f_idx) in item.data" :key="f_idx" class="zem-collapse-table__row">
              <div :class="{'required-field': editProfile}" class="zem-collapse-table__column title">
                {{ field.name }}
              </div>
              <div v-if="!editProfile" class="zem-collapse-table__column text">
                {{ field.value }}
              </div>
              <div v-else-if="editProfile && field.type === 'phone'" class="zem-collapse-table__column text">
                <ValidationProvider
                  v-slot="{errors}"
                  :name="field.name"
                  :rules="{
                    required: true,
                    regex: `^[\\+]?[7]?[8]?\\s[(][0-9]{3}[)]\\s[0-9]{3}[-\\s\\.]?[0-9]{2}[-\\s\\.]?[0-9]{2}$`,
                  }"
                  class="w-100"
                >
                  <ZemInput
                    v-model="field.value"
                    :errors="errorsBackend"
                    class="m-0 mt-0"
                    error-title="client_phone"
                    mask="phone"
                    placeholder="+71234567890"
                    type="tel"
                    @input="errorsBackend = []"
                    @update-phone="field.value = $event"
                  />
                  <p v-show="errors.length !== 0" class="details-form__data-block__error">
                    {{ errors[0] }}
                  </p>
                </ValidationProvider>
              </div>
              <div v-else-if="editProfile && field.type === 'email'" class="zem-collapse-table__column text">
                <ValidationProvider
                  v-slot="{errors}"
                  :name="field.name"
                  :rules="{
                    required: false,
                    email: true,
                  }"
                  class="w-100"
                >
                  <ZemInput
                    v-model="field.value"
                    :placeholder="field.name"
                    class="m-0"
                    disabled
                    isCompact
                    type="email"
                  />
                  <p v-show="errors.length !== 0" class="details-form__data-block__error">
                    {{ errors[0] }}
                  </p>
                </ValidationProvider>
              </div>
              <div v-else class="zem-collapse-table__column text">
                <ZemInput v-model="field.value" :placeholder="field.name" class="m-0" isCompact type="text" />
              </div>
            </div>
          </div>
        </zem-collapse>
        <zem-collapse :title="$t('Organization') || ''">
          <div class="zem-collapse-table rows">
            <div v-for="(item, idx) in newCompanyFields" :key="idx" class="zem-collapse-table__row">
              <div :class="{'required-field': editProfile}" class="zem-collapse-table__column title">
                {{ item.name }}
              </div>
              <div v-if="item.name === 'ИНН'" class="zem-collapse-table__column text">
                <template v-if="priorityOrganization !== '' || !editProfile">{{ item.value }}</template>
                <ZemInput
                  v-else
                  v-model="item.value"
                  :placeholder="item.name"
                  class="m-0"
                  isCompact
                  type="text"
                  @input="getCompanies"
                />
                <template v-if="qCompanies">
                  <p
                    :style="{
                      'font-size': '10px',
                      color: '#FF4D4FFF',
                      margin: '5px 0 0 0',
                    }"
                    class="new-company__input__error zem-collapse-table__column text"
                  >
                    Компания уже существует.
                  </p>
                  <zem-button class="mt-1 mb-1" @click="connectingUserToCompany">Присоединиться</zem-button>
                </template>
              </div>
              <div v-else-if="item.name === 'Страна'" class="zem-collapse-table__column text overflow-unset">
                <template v-if="priorityOrganization !== '' || !editProfile">{{ item.value.title }}</template>
                <ZemDropdownList
                  v-else
                  :default="'Страна'"
                  :options="optionsCountries"
                  :placeholder="'Страна'"
                  :value="item.value"
                  class="select m-0"
                  item="name"
                  type="object"
                  @input="item.value = $event"
                />
              </div>
              <div v-else-if="item.name === 'Регион'" class="zem-collapse-table__column text overflow-unset">
                <template v-if="priorityOrganization !== '' || !editProfile">{{ item.value.title }}</template>
                <ZemDropdownList
                  v-else
                  :default="'Регион'"
                  :options="optionsRegions"
                  :placeholder="'Регион'"
                  :value="item.value"
                  class="select m-0"
                  item="name"
                  type="object"
                  @input="item.value = $event"
                />
              </div>
              <div v-else-if="item.name === 'Город'" class="zem-collapse-table__column text overflow-unset">
                <template v-if="priorityOrganization !== '' || !editProfile">{{ item.value.title }}</template>
                <ZemDropdownList
                  v-else
                  :default="'Город'"
                  :options="optionsCities"
                  :placeholder="'Город'"
                  :value="item.value"
                  class="select m-0"
                  item="name"
                  type="object"
                  @input="item.value = $event"
                />
              </div>
              <div v-else-if="item.name === 'Тип организации'" class="zem-collapse-table__column text overflow-unset">
                <template v-if="priorityOrganization !== '' || !editProfile">{{ item.value.title }}</template>
                <ZemDropdownList
                  v-else
                  :default="'Тип организации'"
                  :options="optionsOwnership"
                  :placeholder="'Тип организации'"
                  :value="item.value"
                  class="select m-0"
                  item="name"
                  type="object"
                  @input="item.value = $event"
                />
              </div>
              <div v-else class="zem-collapse-table__column text">
                <template v-if="priorityOrganization !== '' || !editProfile">{{ item.value }}</template>
                <ZemInput v-else v-model="item.value" :placeholder="item.name" class="m-0" isCompact type="text" />
              </div>
            </div>
          </div>
        </zem-collapse>
      </validation-observer>
    </template>
    <template v-else-if="menuType === 'settings'">
      <div class="settings__main-container">
        <h1 class="settings__label">{{ $t('System settings') }}</h1>
        <img
          v-if="!editSettings && !isSavingParams"
          v-tippy="{content: 'Редактировать'}"
          :src="require('@/assets/icons/edit.svg')"
          alt="#"
          class="settings__edit"
          @click="editSettings = true"
        />
        <template v-else>
          <zem-link class="mr-2 mobile-d-none" v-if="!isSavingParams" @click="editSettings = false">
            {{ $t('Cancel') }}
          </zem-link>
          <zem-button
            :disabled="isSavingParams"
            :loading="isSavingParams"
            class="mobile-d-none"
            @click="onSaveSystemSettings"
          >
            {{ isSavingParams ? 'Сохранение' : $t('Save') }}
          </zem-button>
        </template>
        <div v-if="!isSavingParams" @click="$store.commit('profile/showProfile', false)">
          <zem-icon class="cursor-pointer ml-2" name="close" size="20" />
        </div>
      </div>
      <zem-collapse :title="$t('Localization') || ''">
        <div class="zem-collapse-table rows mt-2">
          <div class="zem-collapse-table__row">
            <div class="zem-collapse-table__column title">
              {{ $t('Language') }}
            </div>
            <ZemDropdownList
              v-model="selectLanguage"
              :options="locales"
              class="mt-0"
              type="object"
              @input="$i18n.locale = $event.locale"
            />
          </div>
        </div>
      </zem-collapse>
      <div v-if="!params" class="d-flex justify-content-center mt-2">
        <zem-preloader color="primary" size="18" />
      </div>
      <template v-else>
        <zem-collapse v-for="setting in params" :key="setting['key']" :title="setting['title']">
          <div class="zem-collapse-table rows mt-2">
            <div v-for="(value, index) in Object.keys(setting.value)" :key="index" class="zem-collapse-table__row">
              <div v-tippy="{content: setting.value[value].title}" class="zem-collapse-table__column title">
                {{ setting.value[value].title }}
              </div>
              <div
                v-tippy="{content: formatValue(setting.value[value])}"
                :class="{'overflow-unset': setting.value[value].type === 'list'}"
                class="zem-collapse-table__column text"
              >
                <zem-button
                  v-if="setting.value[value].type === 'link'"
                  v-tippy="{content: setting.value[value]['hint']}"
                  :disabled="isLoadingUpdateUsers"
                  :loading="isLoadingUpdateUsers"
                  class="mt-0"
                  @click="updateUsers(setting.value[value]['value'])"
                  >Обновить
                </zem-button>
                <template v-else-if="!editSettings">{{ formatValue(setting.value[value]) }}</template>
                <ZemDropdownList
                  v-else-if="setting.value[value].type === 'list'"
                  v-model="setting.value[value].value"
                  v-tippy="{content: setting.value[value]['hint']}"
                  :options="setting.value[value].options"
                  class="mt-0"
                  item="title"
                  type="object"
                />
                <zem-input
                  v-else-if="setting.value[value]['hint']"
                  v-model="setting.value[value].value"
                  v-tippy="{content: setting.value[value]['hint']}"
                  class="mt-0"
                />
                <zem-input v-else v-model="setting.value[value].value" class="mt-0" />
              </div>
            </div>
          </div>
        </zem-collapse>
      </template>
    </template>

    <ZemModal
      :show="showModal"
      :title-cancel="$t('No') || ''"
      :title-success="$t('Yes') || ''"
      title="Уверены, что хотите выйти?"
      @on-change="showModal = !showModal"
      @on-success="logOut"
      @on-cancel="showModal = false"
    />

    <ZemModal
      :show="showChangePasswordModal"
      :title-cancel="$t('Cancel') || ''"
      :title-success="$t('Save') || ''"
      title="Изменить пароль"
      @on-change="showChangePasswordModal = !showChangePasswordModal"
      @on-success="checkedPassword"
      @on-cancel="showChangePasswordModal = false"
    >
      <div class="settings__inputs-block">
        <zem-input v-model="oldPassword" :max-length="255" :placeholder="$t('Old password') || ''" class="mt-3" />
        <zem-input v-model="newPassword" :max-length="255" :placeholder="$t('New password') || ''" />
        <zem-input v-model="repeatPassword" :max-length="255" :placeholder="$t('Repeat password') || ''" />
      </div>
    </ZemModal>
  </div>
</template>

<script>
import Tabs from '@/components/ui/Tabs'
import Security from '@/views/Settings/SettingsSecurity'
import Settings from '@/views/Settings/SettingsAmoCrm'
import SettingsCompany from '@/views/Settings/SettingsCompany'
import SettingsProfile from '@/views/Settings/SettingsProfile'
import UserService from '@/services/user.service'
import {errorHandler, formattedPhone, phoneTrim} from '@/assets/scripts/scripts'
import ZemLink from '@/components/ui/ZemLink.vue'
import UserCard from '@/components/UserCard.vue'
import ZemInput from '@/components/ui/ZemInput.vue'
import ZemCollapse from '@/components/ui/ZemCollapse.vue'
import toastMessage from '@/helpers/messages'
import messages from '@/helpers/messages'
import TokenService from '@/services/token.service'
import ZemModal from '@/components/ui/ZemModal.vue'
import ZemDropdownList from '@/components/ui/ZemDropdownList.vue'
import ZemButton from '@/components/ui/ZemButton.vue'
import ZemIcon from '@/components/ui/ZemIcon.vue'
import OtherService from '@/services/other.service'
import UsersService from '@/services/users.service'
import ZemPreloader from '@/components/ui/ZemPreloader.vue'
import ProfileService from '@/services/profile.service'
import PeopleAndCompaniesService from '@/services/peopleAndCompanies.service'
import axios from 'axios'
import {auth} from '@/helpers/config-axios'
import store from '@/store'

export default {
  components: {
    ZemIcon,
    ZemButton,
    ZemDropdownList,
    ZemModal,
    ZemCollapse,
    ZemInput,
    UserCard,
    ZemLink,
    SettingsProfile,
    SettingsCompany,
    Tabs,
    Security,
    Settings,
    ZemPreloader,
  },
  data() {
    return {
      selectedTab: 'Пользователь',
      itemInfo: {
        type: 'people',
        name: 'Иванов Сергей Петрович',
        phone: '',
        email: '',
        telegram: '',
        id: '123567',
        status: '',
        img: require('@/assets/images/user.webp'),
      },
      newCompanyFields: [
        {
          name: 'ИНН',
          value: '',
        },
        {
          name: 'Название',
          value: '',
        },
        {
          name: 'Страна',
          value: '',
        },
        {
          name: 'Регион',
          value: '',
        },
        {
          name: 'Город',
          value: '',
        },
        {
          name: 'Тип организации',
          value: '',
        },
      ],
      fields: [],
      editProfile: false,
      editSettings: false,
      showModal: false,
      showChangePasswordModal: false,
      unloadingProcess: false,
      downloadUrl: '',
      currentFields: [],
      errorsBackend: [],
      selectLanguage: {
        locale: 'ru',
        title: 'Русский',
      },
      selectBrand: {
        object: null,
        title: 'Выберите бренд',
      },
      locales: [
        {
          locale: 'en',
          title: 'English',
        },
        {
          locale: 'ru',
          title: 'Русский',
        },
      ],
      oldPassword: '',
      newPassword: '',
      repeatPassword: '',
      params: null,
      loadingParams: true,
      isSavingParams: false,
      timer: null,
      optionsOwnership: [],
      optionsCities: [],
      optionsCountries: [],
      optionsRegions: [],
      isLoadingUpdateUsers: false,
    }
  },
  computed: {
    profile() {
      return this.$store.state.user.profile
    },
    menuType() {
      return this.$store.state.profile.settingsValue
    },
    qCompanies() {
      return this.$store.state.profile.companiesSearch.length > 0
    },
    priorityOrganization() {
      return this.profile.human.data.priority_organization_id
    },
  },
  watch: {
    profile() {
      this.getUserData()
    },
  },
  mounted() {
    // OtherService.getSettings()
    //   .then(r => {
    //     this.params = r.data.data.filter(el => el.key === "amo_crm")[0].value
    //   })
    ProfileService.getOwnershipList().then(r => {
      this.optionsOwnership = r.data.data
    })
    ProfileService.getCountriesList().then(r => {
      this.optionsCountries = r.data.data
    })
    ProfileService.getCitiesList().then(r => {
      this.optionsCities = r.data.data
    })
    ProfileService.getRegionsList().then(r => {
      this.optionsRegions = r.data.data
    })
    this.$store.commit('profile/getCompaniesSearch', [])
    this.getUserData()
    if (this.menuType === 'settings') {
      OtherService.getSettingsSystem().then(r => {
        this.params = r.data.data
        this.loadingParams = false
      })
    }
  },
  methods: {
    formatValue(value) {
      if (!value.value) return ''
      if (value.type === 'list') return value.value.title
      return value.value
    },
    updateUsers(link) {
      this.isLoadingUpdateUsers = true
      axios
        .get(link, {headers: auth()})
        .then(() => {
          this.isLoadingUpdateUsers = false
          this.$toast.success('Список AmoCrm пользователей обновлен!')
        })
        .catch(e => {
          if (e.request.status === 417) {
            this.$toast.error(e.response.data.message)
          } else {
            this.$toast.error(JSON.parse(e.request.responseText).message)
          }
        })
    },
    getCompanies() {
      this.$store.commit('profile/getCompaniesSearch', [])
      clearTimeout(this.timer)
      this.timer = null
      this.timer = setTimeout(() => {
        ProfileService.getCompaniesSearch(this.newCompanyFields[0].value)
      }, 500)
    },
    connectingUserToCompany(id = null) {
      UserService.updateProfile({
        organizations: [id === null ? this.$store.state.profile.companiesSearch[0].id : id],
      })
        .then(() => {
          this.$store.commit('profile/readProfile', false)
          this.editProfile = false
          this.$store.commit('profile/getCompaniesSearch', [])

          UserService.updateProfile({
            priority_organization_id: id === null ? this.$store.state.profile.companiesSearch[0].id : id,
          })
            .then(r => {
              this.$store.commit('profile/readProfile', false)
              PeopleAndCompaniesService.getCompany(r.data.data.human.data.priority_organization_id)
                .then(r => {
                  this.$store.commit('user/getOrganization', r.data.data)
                })
                .catch(e => {
                  errorHandler(e)
                })
              this.$toast.success(messages.connectingUserToCompany)
            })
            .catch(e => {
              if (e.request.status === 417) {
                this.$toast.error(e.response.data.message)
              } else {
                this.$toast.error(JSON.parse(e.request.responseText).message)
              }
            })
        })
        .catch(e => {
          if (e.request.status === 417) {
            this.$toast.error(e.response.data.message)
          } else {
            this.$toast.error(JSON.parse(e.request.responseText).message)
          }
        })
      this.showFieldsNewCompany = false
    },
    getUserData() {
      const phone = this.profile.human.data.phone

      this.itemInfo = {
        type: 'people',
        name: this.profile.human.data.fio,
        phone: formattedPhone(phone),
        email: this.profile.email,
        telegram: '8 912 123 45 67',
        id: this.profile.human.data.number,
        id2: this.profile.id,
        status: 'Активный',
        img: require('@/assets/images/user.webp'),
      }

      this.fields = [
        {
          title: 'Контакты',
          data: [
            {
              name: 'ФИО',
              value: this.profile.human.data.fio,
              new: false,
              type: 'text',
              read: true,
            },
            {
              name: 'Телефон',
              value: formattedPhone(phone),
              type: 'phone',
              new: false,
              read: true,
            },
            {
              name: 'Email',
              value: this.profile.email,
              type: 'email',
              new: false,
              read: true,
            },
          ],
          possibleToCreate: [
            {
              name: 'Контакт',
            },
          ],
        },
      ]

      if (this.priorityOrganization)
        PeopleAndCompaniesService.getCompany(this.priorityOrganization).then(r => {
          const {city, country, inn, organization_ownership, region, title} = r.data.data
          this.newCompanyFields = [
            {
              name: 'ИНН',
              value: inn,
            },
            {
              name: 'Название',
              value: title,
            },
            {
              name: 'Страна',
              value: country ? {id: country.data.id, title: country.data.name} : '',
            },
            {
              name: 'Регион',
              value: region ? {id: region.data.id, title: region.data.name} : '',
            },
            {
              name: 'Город',
              value: city ? {id: city.data.id, title: city.data.name} : '',
            },
            {
              name: 'Тип организации',
              value: organization_ownership ? organization_ownership.data : '',
            },
          ]
        })
    },
    updateProfile() {
      this.$refs.profileForm.validate().then(success => {
        if (success) {
          UserService.updateProfile({
            fio: this.fields[0].data[0].value,
            phone: phoneTrim(this.fields[0].data[1].value),
          })
            .then(() => {
              UserService.getUserProfile().then(r => {
                this.$store.commit('user/profileSuccess', r.data.data)
              })
              this.editProfile = false
              this.$toast.success(toastMessage.updateProfile)
            })
            .catch(e => {
              if (e.request.status === 417) {
                this.$toast.error(e.response.data.message)
              } else {
                this.$toast.error(JSON.parse(e.request.responseText).message)
              }
            })
          if (this.priorityOrganization === '') this.createCompany()
        } else {
          this.$toast.error(toastMessage.errorValidForm)
        }
      })
    },
    createCompany() {
      ProfileService.createCompany({
        title: this.newCompanyFields[1].value,
        inn: this.newCompanyFields[0].value,
        country_id: this.newCompanyFields[2].value.id,
        region_id: this.newCompanyFields[3].value.id,
        city_id: this.newCompanyFields[4].value.id,
        organization_ownership_id: this.newCompanyFields[5].value.id,
      })
        .then(r => {
          this.showFieldsNewCompany = false
          this.connectingUserToCompany(r.data.data.id)
          this.$emit('update-profile')
          this.$toast.success(messages.createOrganization)
        })
        .catch(e => {
          if (e.request.status === 417) {
            this.$toast.error(e.response.data.message)
          } else {
            this.$toast.error(JSON.parse(e.request.responseText).message)
          }
        })
    },
    cancelProfile() {
      this.editProfile = !this.editProfile
      this.getUserData()
    },
    logOut() {
      this.$store.commit('profile/showProfile', false)
      this.showModal = false
      localStorage.removeItem('user')
      TokenService.deleteLocalAccessToken()
      TokenService.deleteLocalRefreshToken()
      this.$store.dispatch('auth/logout')
      this.$store.commit('other/SET_TIMESTAMP', 0)
      this.$router.push('/login')
    },
    checkedPassword() {
      if (this.oldPassword === '' || this.newPassword === '' || this.repeatPassword === '') {
        this.$toast.error('Необходимо заполнить все поля')
      } else if (this.oldPassword === this.newPassword) {
        this.$toast.error('Старый и новый пароль совпадают')
      } else if (this.newPassword !== this.repeatPassword) {
        this.$toast.error('Новый пароль не совпадает')
      } else {
        OtherService.checkPassword({
          password: this.oldPassword,
        }).then(r => {
          if (r.status === 200) {
            UsersService.updateUser(this.$store.state.user.profile.id, {password: this.newPassword}, true)
              .then(r => {
                if (r.status === 200) {
                  this.$toast.success('Новый пароль изменен')
                  this.showChangePasswordModal = false
                }
              })
              .catch(e => {
                errorHandler(e)
              })
          } else if (r.status === 204) {
            this.$toast.error('Вы ввели неверный пароль')
          }
        })
      }
    },
    uploadData() {
      this.unloadingProcess = true
      OtherService.uploadDataInCSV().then(r => {
        this.downloadUrl = r.data.data['file_url']
        this.$toast.success(this.$t('The upload is completed'))
        this.unloadingProcess = false
      })
    },
    downloadData() {
      const fURL = document.getElementById('download-file')

      fURL.href = this.downloadUrl
      fURL.setAttribute('download', 'file.csv')

      fURL.click()
    },
    async onSaveSystemSettings() {
      this.editSettings = false
      this.isSavingParams = true
      let data = {}
      for (const i in this.params) {
        data = {
          key: this.params[i].key,
          value: {},
          type: 'data',
        }

        for (const v in this.params[i].value) {
          const {type, value} = this.params[i].value[v]
          data.value[v] = type === 'list' ? value.value : value
        }
        await OtherService.updateSettings(data).then(() => {
          if (Number(i) === this.params.length - 1) {
            this.$toast.success('Настройки системы сохранены')
            this.isSavingParams = false
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.select {
  width: 100%;
}

.settings {
  padding: 20px;

  &__main-container {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    height: 24px;
  }

  &__tag {
    margin-right: 15px;
  }

  &__tag,
  &__logout {
    height: 20px;
    cursor: pointer;
  }

  &__edit {
    height: 16px;
    cursor: pointer;
  }

  &__label {
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: #3e3e3e;
    flex-grow: 1;
  }
}

.new-company {
  width: 470px;
  margin-top: 12px;

  &__input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;

    &__error {
      font-size: 10px;
      color: $color-red;
      margin: 5px 0 0 0;
    }
  }

  &__column {
    flex: 1;

    &.title {
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      color: $color-gull-gray;
      margin-right: 10px;
      padding-top: 5px;
    }

    &--second {
      flex: 1;
      cursor: pointer;
    }

    &--select {
      width: 244px;
      justify-content: space-between !important;
      align-items: center !important;
    }
  }

  &__row {
    display: flex;
    align-items: flex-start;
    align-self: flex-start;
    align-content: inherit;
    text-align: left;
    justify-content: flex-start;
    margin-bottom: 8px;
  }
}
</style>
