import Vue from "vue"
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"

/**
 * NOTE: If you are using other transition them make sure to import it in `src/@core/scss/vue/libs/notification.scss` from it's source
 */
Vue.use(Toast, {
  closeOnClick: false,
  closeButton: 'button',
  icon: false,
  timeout: 1800,
  transition: 'Vue-Toastification__fade',
})
