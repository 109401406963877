export const filterPeoplesAndCompany = {
  namespaced: true,

  state: {
    isActive: null,
  },

  mutations: {
    changeIsActive(state, data) {
      state.isActive = data
    },
  },

  actions: {},
}
