export const events = {
  namespaced: true,

  state: {
    isGetAllItemsSuccess: false,
    deleteAllItemsSuccess: false,
    elements: [],
    selectedElements: [],
    eventsPagination: {
      custom: {
        total: 0,
      },
      pagination: {
        current_page: 1,
        total_pages: 1,
        total: 0,
      },
    },
    selectedItemsForDelete: [],
    paginationPage: 1,
    filter: {},
    orderBy: null,
    sortedBy: null,
    search: '',
    searchCount: 0,
    statusEvents: false,
    loading: {
      takeInWork: false,
      takeEventsInWork: false,
      takeAllEventsInWork: false,
    },
    isArchive: false,
  },

  mutations: {
    getAllElements(state, events) {
      state.isGetAllItemsSuccess = true
      state.elements = events.data
      if (events.meta) state.eventsPagination = events.meta
    },
    getSelectedEvents(state, events) {
      state.selectedElements = events.data
    },
    getAllEventsFailure(state) {
      state.isGetAllItemsSuccess = false
      state.elements = null
      state.eventsPagination = {
        custom: {
          total: 0,
          settings: {
            value: {},
          },
        },
        pagination: {
          current_page: 1,
          total_pages: 1,
          total: 0,
        },
      }
    },
    changePagination(state) {
      state.eventsPagination = state
    },
    deleteAllEvents(state, data) {
      state.deleteAllItemsSuccess = data
    },
    itemsForDelete(state, data) {
      state.selectedItemsForDelete = data
    },
    getAddress(state, data) {
      state.address = data.data.suggestions
    },
    getStatuses(state, data) {
      state.statuses = data
    },
    getPartnerStatuses(state, data) {
      state.partnerStatuses = data
    },
    filterMain(state, data) {
      state.filter['main'] = data
    },
    changePage(state, data) {
      state.paginationPage = data
    },
    sorting(state, data) {
      state.sortedBy = data
    },
    ordering(state, data) {
      state.orderBy = data
    },
    getSearch(state, data) {
      state.search = data
    },
    getSearchCount(state, data) {
      state.searchCount = data
    },
    updateStatusEvents(state, data) {
      state.statusEvents = data
    },
    changeIsArchive(state, data) {
      state.isArchive = data
    },
    changeCurrentPage(state) {
      state.paginationPage = 1
    },
    changeTakeInWorkPreloader(state, data) {
      state.loading.takeInWork = data
    },
    changeTakeEventsInWorkPreloader(state, data) {
      state.loading.takeEventsInWork = data
    },
    changeTakeAllEventsInWorkPreloader(state, data) {
      state.loading.takeAllEventsInWork = data
    },
    clearState(state) {
      state = {
        isGetAllItemsSuccess: false,
        deleteAllEventsSuccess: false,
        elements: [],
        selectedElements: [],
        eventsPagination: {
          custom: {
            total: 0,
            settings: {
              value: {},
            },
          },
          pagination: {
            current_page: 1,
            total_pages: 1,
            total: 0,
          },
        },
        statuses: [],
        partnerStatuses: [],
        selectedItemsForDelete: [],
        paginationPage: 1,
        filter: {},
        orderBy: null,
        sortedBy: null,
        search: '',
        searchCount: 0,
        statusEvents: false,
      }
    },
  },

  actions: {},
}
