<template>
  <a :class="{'zem-link__disabled': disabled}" class="zem-link" @click.prevent="onClickEvent">
    <slot />
  </a>
</template>

<script>
export default {
  name: 'ZemLink',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClickEvent() {
      this.$emit('click')
    },
  },
}
</script>

<style lang="scss" scoped>
.zem-link {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  text-align: center;
  color: $color-accent;
  cursor: pointer;

  &__disabled {
    opacity: 0.7;

    &:hover {
      text-decoration: none;
    }
  }

  &:hover {
    //text-decoration: underline;
  }
}
</style>
