<template>
  <img
    :src="require(`@/assets/icons/${name}.svg`)"
    :style="{width: size + 'px', height: size + 'px'}"
    alt=""
    class="zem-icon"
  />
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '14',
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped></style>
