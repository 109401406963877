export const user = {
  namespaced: true,

  state: {
    status: {
      loggedIn: true,
    },
    profile: {
      name: null,
      email: null,
      human: {
        data: {
          organizations: {
            data: [],
          },
          organization: {
            data: null,
          },
          priority_organization: {
            data: {
              title: null,
              phone: null,
              email: null,
            },
          },
        },
      },
    },
    organization: {
      data: null,
    },
    humans: null,
    human: null,
    role: 'all',
    ability: []
  },

  mutations: {
    profileSuccess(state, profile) {
      state.profile = profile
      state.role = profile.roles.data.length === 0 ? 'user' : profile.roles.data[0].name
    },
    getAllHumansSuccess(state, humans) {
      state.humans = humans
    },
    getOrganization(state, organization) {
      state.organization = organization
    },
    setAbility(state, ability) {
      state.ability = ability
    },
  },
}
