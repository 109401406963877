<template>
  <div :class="$route.meta['layout'] === 'full' ? 'full-layout' : 'default-layout'" class="main-layout">
    <template v-if="$route.meta['layout'] !== 'full'">
      <div class="main-layout__mobile-menu">
        <div class="main-layout__title">
          <img :src="require('@/assets/logo.png')" alt="" />
          <h3>ERP | Управление ресурсами компании</h3>
        </div>
        <div class="tw-h-[25px] tw-flex tw-items-center" @click="showMenu">
          <div class="main-layout__burger-menu"></div>
        </div>
      </div>
      <div v-if="menu" :class="{show: $store.state.other.showMobileMenu}" class="main-layout__menu">
        <div class="main-layout__menu-close-btn" @click="showMenu">
          <zem-icon class="cursor-pointer" name="close" size="18" />
        </div>
        <slot name="menu"></slot>
      </div>
    </template>
    <div :class="$route.meta['layout'] !== 'full' ? 'margin-mobile' : ''" class="main-layout__body">
      <slot name="body"></slot>

      <div v-if="$store.state.profile.show || $store.state.profile.showSyncAmo" class="main-layout__sidebar">
        <slot name="sidebar"></slot>
      </div>

      <div
        v-if="$store.state.profile.show || $store.state.profile.showSyncAmo"
        class="dark_block"
        @click="closeSidebar"
      ></div>
    </div>
  </div>
</template>

<script>
import ZemIcon from '@/components/ui/ZemIcon'

export default {
  components: {
    ZemIcon,
  },
  data() {
    return {
      vcoConfig: {
        handler: this.onClickOutside,
        middleware: this.middleware,
        events: ['dblclick', 'click'],
        isActive: true,
        detectIFrame: true,
        capture: false,
      },
      menu: false,
    }
  },
  mounted() {
    if (this.$route.meta['layout'] === undefined) this.menu = true
  },
  methods: {
    onClickOutside() {
      this.$store.commit('profile/showProfile', false)
    },
    showMenu() {
      this.$store.commit('other/changeMobileMenu', !this.$store.state.other.showMobileMenu)
    },
    closeSidebar() {
      this.$store.commit('profile/showProfile', false)
      this.$store.commit('profile/showSyncAmo', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.main-layout {
  width: 100vw;
  height: 100dvh;
  display: none;

  &__menu {
    display: none;
    height: 100dvh;
    width: 80px;
  }

  &__body {
    height: 100dvh;
    flex-grow: 1;
    overflow: auto;
  }

  &__sidebar {
    position: absolute;
    top: 0;
    background-color: #fff;
    width: 520px;
    height: 100%;
    overflow-y: auto;
    box-shadow: 2px 0 8px #8d8d8d1f;
    z-index: 11;
  }

  .dark_block {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    background-color: #00000050;
    z-index: 10;
  }

  &.default-layout {
    display: flex;

    .main-layout__menu {
      display: block;
    }
  }

  &.full-layout {
    display: flex;
  }

  &__mobile-menu {
    display: none;
    padding: 20px 20px;
  }

  &__burger-menu {
    height: 4px;
    width: 25px;
    min-width: 25px;
    background: $color-accent;
    border-radius: 2px;

    &::before {
      content: '';
      height: 4px;
      width: 25px;
      background: $color-accent;
      position: absolute;
      top: 12px;
      border-radius: 2px;
    }

    &::after {
      content: '';
      height: 4px;
      width: 25px;
      background: $color-accent;
      position: absolute;
      top: 30px;
      border-radius: 2px;
    }
  }

  &__menu-close-btn {
    display: none;
    position: absolute;
    z-index: 15;
    top: 15px;
    right: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .main-layout {
    flex-direction: column;

    &__menu {
      height: 100%;
      width: 100%;
      position: fixed;
      top: 0;
      z-index: 10000;
      overflow: auto;
      transition: transform 0.3s ease;
      transform: translateX(-100%);

      &.show {
        transform: translateX(0);
      }
    }

    &__sidebar {
      width: 100%;
      top: 45px;
      max-width: unset;
      height: calc(100% - 45px);
    }

    &__mobile-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: sticky;
      width: 100%;
      z-index: 50;
      background: #fefeff;
      height: 45px;
      padding: 5px 20px;
      box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    }

    &__title {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 5px 0;
      font-size: 12px;
      color: #3e3e3e;

      img {
        height: 100%;
      }

      h3 {
        padding: 0 20px 0 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &__menu-close-btn {
      display: block;
    }
  }
}
</style>
