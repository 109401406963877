<template>
    <div v-if="$store.state.sidebars[sidebarName]" class="arrow-container" @click="onClicked">
        <img :class="arrowClass" alt="" class="arrow-container__image" src="../../assets/icons/arrow_2.svg"/>
    </div>
</template>

<script>
export default {
    name: 'Arrow',
    props: {
        isInverted: {
            type: [Boolean],
            default: false
        },
        isNeedHideArrow: {
            type: [Boolean],
            default: false
        },
        sidebarName: {
            type: String,
        }
    },

    data() {
        return {
            isArrowClicked: false
        };
    },

    computed: {
        arrowClass() {
            return this.$store.state.sidebars[this.sidebarName] ? 'arrow-container__image--reverse' : 'arrow-container__image';
        }
    },

    methods: {
        onClicked() {
            let newStr = this.sidebarName[0].toUpperCase() + this.sidebarName.slice(1);
            this.$store.commit(`sidebars/change${newStr}`, !this.$store.state.sidebars[this.sidebarName])
        }
    }
};
</script>

<style lang="scss" scoped>
.arrow-container {
    width: 32px;
    height: 32px;
    background-color: $color-accent;
    box-shadow: 0px 2px 6px rgba($color-accent, $alpha: 0.2);
    border-radius: 100px;
    display: flex;
    z-index: 2;

    &__image {
        width: 14px;
        height: 12px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;

        &--reverse {
            transform: scaleX(-1);
        }
    }
}
</style>
