export const peoples = {
  namespaced: true,

  state: {
    peoples: [],
    selectedPeoples: [],
    isGetAllPeoplesSuccess: false,
    peoplesPagination: {
      custom: {
        total: null,
      },
      pagination: {
        current_page: null,
        total_pages: null,
        total: null,
      },
    },
    selectedItemsForDelete: [],
    paginationPage: 1,
    filter: {},
    orderBy: null,
    sortedBy: null,
    search: '',
    isActive: null,
    isArchive: false,
    searchCount: 0,
  },

  mutations: {
    getAllPeoples(state, peoples) {
      state.isGetAllPeoplesSuccess = true
      state.peoples = peoples.data
      state.peoplesPagination = peoples.meta
    },
    changePagination(state) {
      state.peoplesPagination = state
    },
    getSelectedPeoples(state, peoples) {
      state.selectedPeoples = peoples.data
    },
    sorting(state, data) {
      state.sortedBy = data
    },
    ordering(state, data) {
      state.orderBy = data
    },
    filterMain(state, data) {
      state.filter['main'] = data
    },
    itemsForDelete(state, data) {
      console.log(data)
      state.selectedItemsForDelete = data
    },
    getSearch(state, data) {
      state.search = data
    },
    changePage(state, data) {
      state.paginationPage = data
    },
    changeIsActive(state, data) {
      state.isActive = data
    },
    changeIsArchive(state, data) {
      state.isArchive = data
    },
    getSearchCount(state, data) {
      state.searchCount = data
    },
  },

  actions: {},
}
