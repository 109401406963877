import * as rules from "vee-validate/dist/rules";
import {extend, localize, ValidationObserver, ValidationProvider} from "vee-validate";
import ru from "vee-validate/dist/locale/ru.json"
import Vue from "vue";

Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule])
})

localize("ru", ru)

// Install components globally
Vue.component("ValidationObserver", ValidationObserver)
Vue.component("ValidationProvider", ValidationProvider)