<template>
    <span :class="{ 'error': type === 'error' }" class="zem-badge">
        <slot/>
    </span>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: ''
        }
    },
    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped>
.zem-badge {
    padding: 0.1rem 0.4rem;
    border-radius: 0.3rem;
    background-color: $color-accent;
    color: #fff;
    font-size: 11px;

    &.error {
        background-color: $color-red;
    }
}
</style>
