<template>
    <div class="zem-dropdown">
        <v-select
            v-model="value"
            :components="{ OpenIndicator }"
            :options="options"
            label="title"
            multiple
            placeholder="Выбрать"
            @input="change"
            @open="open"
            :disabled="disabled"
        >
            <template #open-indicator="{ attributes }">
                <div
                    v-if="loading"
                    class="zem-dropdown-head__preloader"
                >
                    <zem-preloader color="primary"/>
                </div>
                <img
                    :src="require('@/assets/icons/dropdown-arrow.svg')"
                    alt=""
                />
            </template>
            <template v-slot:no-options="{ search, searching }">
                <span style="opacity: 0.5; font-size: 12px;">{{ $t('Nothing found') }}</span>
            </template>
        </v-select>
    </div>
</template>

<script>
import "vue-select/dist/vue-select.css"
import ZemPreloader from "@/components/ui/ZemPreloader";

export default {
    components: {
        ZemPreloader
    },
    props: {
        value: {
            type: [Array, Object],
            default: null,
        },
        options: {},
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            OpenIndicator: {
                render: createElement => createElement("span", ""),
            },
            selected: [],
            showPreloader: false,
            defaultData: [],
        }
    },
    mounted() {
        this.selected = this.defaultData
    },
    watch: {
        options() {
            this.selected = this.defaultData
            this.showPreloader = false
        },
        value() {
            this.selected = this.value
        }
    },
    methods: {
        open() {
            this.$emit('open', true)
            this.showPreloader = true
        },
        change(e) {
            const els = e.map(el => {
                return {
                    ...el,
                    title: el.title.replaceAll('--', '')
                }
            })

            if (els[els.length - 1].id === 0) {
                this.selected = [els[els.length - 1]]
                this.$emit('input', [els[els.length - 1]])
                this.$emit('change', [els[els.length - 1]])
            } else if (els[0].id === 0) {
                const newArr = els.find(item => item.id !== 0)
                this.selected = newArr
                this.$emit('input', [newArr])
                this.$emit('change', [newArr])
            } else {
                this.selected = els
                this.$emit('input', els)
                this.$emit('change', els)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.zem-dropdown {
    width: 100%;

    .zem-dropdown-head {

        &__arrow {
            transition: all 0.25s;
        }

        &__preloader {
            position: absolute;
            right: 26px;
            width: 10px;
        }

        &.open {
            .zem-dropdown-head__arrow {
                transform: rotate(180deg);
            }
        }
    }
}
</style>
