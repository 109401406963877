export const other = {
  namespaced: true,

  state: {
    roles: [],
    rolesForRegister: [],
    regions: [
      {
        title: null,
      },
    ],
    onlyRegions: [
      {
        title: null,
      },
    ],
    statusDropDown: false,
    showMobileMenu: false,
    showDesktopMenu: false,
    windowWidth: 0,
    isLoadingItems: false,
    isLoadingItem: false,
    isLoadingSidebar: false,
    isLoadingNewItem: false,
    isRouting: false,
    timestamp: 0,
  },

  getters: {
    currentBreakPoint: state => {
      const {windowWidth} = state
      if (windowWidth >= 1140) return 'xl'
      if (windowWidth >= 960) return 'lg'
      if (windowWidth >= 720) return 'md'
      if (windowWidth >= 540) return 'sm'
      return 'xs'
    },
    mobileContent: state => {
      const {windowWidth} = state
      return windowWidth < 1024
    },
  },

  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    onChangeLoadingItems(state, data) {
      state.isLoadingItems = data
    },
    onChangeLoadingItem(state, data) {
      state.isLoadingItem = data
    },
    onChangeLoadingSidebar(state, data) {
      state.isLoadingSidebar = data
    },
    onChangeLoadingNewItem(state, data) {
      state.isLoadingNewItem = data
    },
    getRolesForRegister(state, roles) {
      state.rolesForRegister = roles
    },
    getRoles(state, roles) {
      state.roles = roles
    },
    getRegions(state, data) {
      state.regions = data
    },
    getOnlyRegions(state, data) {
      state.onlyRegions = data
    },
    updateDropDown(state, data) {
      state.statusDropDown = data
    },
    changeMobileMenu(state, data) {
      state.showMobileMenu = data
    },
    changeDesktopMenu(state, data) {
      state.showDesktopMenu = data
    },
    CHANGE_ROUTING(state, data) {
      state.isRouting = data
    },
    SET_TIMESTAMP(state, val) {
      state.timestamp = val
    },
  },

  actions: {},
}
