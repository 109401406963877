<template>
    <div class="zem-tree">
        <ul>
            <zem-tree-item
                v-for="(child, index) in treeData"
                :key="index"
                class="item"
                :item="child"
                :index-item="index + 1"
                :numbers="numbers"
                @select-element="selectItem"
            ></zem-tree-item>
        </ul>
    </div>
</template>

<script>
import ZemTreeItem from "@/components/ui/ZemTreeItem";

export default {
    components: {
        ZemTreeItem,
    },
    props: {
        treeData: Array,
        numbers: Boolean
    },
    methods: {
        selectItem(el) {
            this.$emit("select-element", el);
        }
    }
}
</script>

<style lang="scss">
.zem-tree {
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: #484848;

    span:hover {
        color: #828b94;
    }

    .zem-select-wood {
        padding-right: 6px;
        padding-left: 0;
        width: 15px;

        &.rotate {
            padding-right: 0;
            padding-left: 6px;
            transform: rotate(180deg);
        }
    }

    ul {
        padding-left: 15px;
        //line-height: 1.5em;
        margin: 0;
        list-style-type: none;
    }

    > ul {
        padding-left: 0;
    }

    .item {
        cursor: pointer;
        margin: 10px 0;
    }
}
</style>
