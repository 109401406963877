export const history = {
  namespaced: true,

  state: {
    isGetAllItemsSuccess: false,
    deleteAllItemsSuccess: false,
    elements: [],
    selectedElements: [],
    historyPagination: {
      custom: {
        total: 0,
      },
      pagination: {
        current_page: 1,
        total_pages: 1,
        total: 0,
      }
    },
    address: [],
    regions: [
      {
        title: null,
      },
    ],
    onlyRegions: [
      {
        title: null,
      },
    ],
    statuses: [],
    partnerStatuses: [],
    selectedItemsForDelete: [],
    paginationPage: 1,
    filter: {},
    orderBy: null,
    sortedBy: null,
    search: '',
    searchCount: 0,
    statusHistory: false,
    loading: {
      takeInWork: false,
      takeHistoryInWork: false,
      takeAllHistoryInWork: false,
    },
    isArchive: false,
  },

  mutations: {
    getAllElements(state, history) {
      state.isGetAllItemsSuccess = true
      state.elements = history.data
      if (history.meta) state.historyPagination = history.meta
    },
    getSelectedHistory(state, history) {
      state.selectedElements = history.data
    },
    getAllHistoryFailure(state) {
      state.isGetAllItemsSuccess = false
      state.elements = null
      state.historyPagination = {
        custom: {
          total: 0,
          settings: {
            value: {},
          },
        },
        pagination: {
          current_page: 1,
          total_pages: 1,
          total: 0,
        },
      }
    },
    changePagination(state) {
      state.historyPagination = state
    },
    deleteAllHistory(state, data) {
      state.deleteAllItemsSuccess = data
    },
    itemsForDelete(state, data) {
      state.selectedItemsForDelete = data
    },
    getAddress(state, data) {
      state.address = data.data.suggestions
    },
    getStatuses(state, data) {
      state.statuses = data
    },
    getPartnerStatuses(state, data) {
      state.partnerStatuses = data
    },
    filterMain(state, data) {
      state.filter['main'] = data
    },
    changePage(state, data) {
      state.paginationPage = data
    },
    sorting(state, data) {
      state.sortedBy = data
    },
    ordering(state, data) {
      state.orderBy = data
    },
    getSearch(state, data) {
      state.search = data
    },
    getSearchCount(state, data) {
      state.searchCount = data
    },
    updateStatusHistory(state, data) {
      state.statusHistory = data
    },
    changeIsArchive(state, data) {
      state.isArchive = data
    },
    changeCurrentPage(state) {
      state.paginationPage = 1
    },
    changeTakeInWorkPreloader(state, data) {
      state.loading.takeInWork = data
    },
    changeTakeHistoryInWorkPreloader(state, data) {
      state.loading.takeHistoryInWork = data
    },
    changeTakeAllHistoryInWorkPreloader(state, data) {
      state.loading.takeAllHistoryInWork = data
    },
    clearState(state) {
      state = {
        isGetAllItemsSuccess: false,
        deleteAllHistorySuccess: false,
        elements: [],
        selectedElements: [],
        historyPagination: {
          custom: {
            total: 0,
            settings: {
              value: {},
            },
          },
          pagination: {
            current_page: 1,
            total_pages: 1,
            total: 0,
          },
        },
        address: [],
        regions: [
          {
            title: null,
          },
        ],
        onlyRegions: [
          {
            title: null,
          },
        ],
        statuses: [],
        partnerStatuses: [],
        selectedItemsForDelete: [],
        paginationPage: 1,
        filter: {},
        orderBy: null,
        sortedBy: null,
        search: '',
        searchCount: 0,
        statusHistory: false,
        loading: {
          takeInWork: false,
          takeHistoryInWork: false,
          takeAllHistoryInWork: false,
        },
      }
    },
  },

  actions: {},
}
