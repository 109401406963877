<template>
  <div class="settings">
    <div class="settings__container">
     <div class="settings__image-block mt-4 mb-0">{{ $t('System settings') }}</div>
      <div class="zem-collapse-table rows mt-2">
        <div class="zem-collapse-table__row">
          <div class="zem-collapse-table__column title">
            {{ $t('Interface language') }}
          </div>
          <ZemDropdownList
            v-model="selectLanguage"
            :options="locales"
            class="mt-0"
            type="object"
            @input="$i18n.locale = $event.locale"
          />
        </div>
        <div class="zem-collapse-table__row">
          <div class="zem-collapse-table__column title">
            {{ $t('Unloading data') }}
          </div>
          <ZemDropdownList
            v-model="selectBrand"
            :options="brands"
            :placeholder="$t('Choose a brand')"
            class="mt-0"
            type="object"
          />
        </div>
        <div v-if="selectBrand.object" class="zem-collapse-table__row">
          <div class="zem-collapse-table__column title"></div>
          <zem-button
            v-if="!downloadUrl"
            :loading="unloadingProcess"
            class="mt-0"
            @click="uploadData"
          >
            <zem-icon
              v-if="!unloadingProcess"
              alt=""
              class="mr-2"
              name="upload-solid"
            />
            <span v-if="!unloadingProcess">{{ $t('Unload') }}</span>
            <span v-else>{{ $t('Unloading') }}...</span>
          </zem-button>
          <zem-button
            v-else
            class="mt-0"
            @click="downloadData"
          >
            <zem-icon
              v-if="!unloadingProcess"
              alt=""
              class="mr-2"
              name="download-solid"
            />
            <span>{{ $t('Download') }}</span>
          </zem-button>
        </div>
      </div>

      <!--            <zem-button @click="saveKeyForCompany">-->
      <!--                <img-->
      <!--                    :src="require('@/assets/icons/check.svg')"-->
      <!--                    alt=""-->
      <!--                    class="mr-1"-->
      <!--                />-->
      <!--                {{ $t('Save') }}-->
      <!--            </zem-button>-->
    </div>
    <a id="download-file" class="d-none" href=""></a>
  </div>
</template>

<script>
import ZemInput from "@/components/ui/ZemInput.vue"
import ZemCheckbox from "@/components/ui/Checkbox.vue"
import ZemButton from "@/components/ui/ZemButton.vue"
import peopleAndCompaniesService from "@/services/peopleAndCompanies.service"
import PeopleAndCompaniesService from "@/services/peopleAndCompanies.service"
import toastMessage from "@/helpers/messages"
import ZemDropdownList from "@/components/ui/ZemDropdownList.vue";
import ZemIcon from "@/components/ui/ZemIcon.vue";
import DirectoryService from "@/services/directory.service";
import OtherService from "@/services/other.service";

export default {
  name: "Settings",
  components: {
    ZemIcon,
    ZemInput,
    ZemButton,
    ZemCheckbox,
    ZemDropdownList,
  },
  data() {
    return {
      checkExchange: true,
      params: {
        amocrm_client_id: "",
        amocrm_client_secret: "",
        amocrm_redirect_uri: "",
        amocrm_authorization_code: "",
        amocrm_account_domain: "",
        amocrm_lead_custom_field_id_address: "",
        amocrm_lead_custom_field_id_coordinates: "",
        amocrm_custom_lead_new_name: "",
        amocrm_contact_custom_field_id_phone: "",
        amocrm_contact_custom_field_id_email: "",
      },
      selectLanguage: {
        locale: 'ru',
        title: 'Русский',
      },
      unloadingProcess: false,
      downloadUrl: '',
      selectBrand: {
        object: null,
        title: 'Выберите бренд',
      },
      locales: [
        {
          locale: 'en',
          title: 'English',
        },
        {
          locale: 'ru',
          title: 'Русский',
        },
        // {
        //     locale: 'de',
        //     img: require('@/assets/images/flags/de.png'),
        //     name: 'German',
        // },
        // {
        //     locale: 'pt',
        //     img: require('@/assets/images/flags/pt.png'),
        //     name: 'Portuguese',
        // },
      ]
    }
  },

  mounted() {
    this.params = this.$store.state.user.organization.params
    DirectoryService.getDirectory('directory/brands')
    // this.$i18n.locale = this.selectLanguage.locale
  },

  watch: {
    selectBrand() {
      this.downloadUrl = ''
    }
  },

  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
    brands() {
      return this.$store.state.directory.directoryItems
    },
  },

  methods: {
    uploadData() {
      this.unloadingProcess = true
      OtherService.uploadDataInCSV(this.selectBrand.id)
        .then(r => {
          this.downloadUrl = r.data.data['file_url']
          this.$toast.success(this.$t('The upload is completed'))
          this.unloadingProcess = false
        })
    },
    downloadData() {
      const fURL = document.getElementById('download-file');

      fURL.href = this.downloadUrl;
      fURL.setAttribute('download', 'file.csv');

      fURL.click();
    },
    saveKeyForCompany() {
      peopleAndCompaniesService
        .updateCompany(this.$store.state.user.organization.id, {
          params: this.params,
        })
        .then(r => {
          this.$toast.success(toastMessage.updateKeyAmoCrm)
          PeopleAndCompaniesService.getCompany(
            this.$store.state.user.organization.id
          ).then(r => {
            this.$store.commit("user/getOrganization", r.data.data)
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.settings {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  align-self: center;
  align-content: center;
  height: 100%;
  text-align: left;

  &__container {
    //padding: 0 25px;
    width: 470px;
  }

  &__label {
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: $color-gull-gray;
    text-align: left;
    display: flex;
  }

  &__image-block {
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    display: flex;
    color: $color-mine-shaft;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 22px;
  }

  &__inputs-block {
    width: 190px;
  }

  label {
    margin-top: 11px;
  }

  button {
    margin-top: 22px;
  }
}
</style>
