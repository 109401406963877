<template>
  <div v-if="showMenu" class="menu tw-space-y-4 max-lg:tw-space-y-6">
    <div class="menu__start-btn" @click="openUserMenu"></div>

    <!-- Products / Продукты -->
    <RouterLink
      v-if="$can('edit', 'directory-item-read')"
      class="menu__item-container tw-relative tw-group"
      to="/products"
    >
      <div
        class="tw-cursor-pointer tw-gap-x-1 tw-items-center lg:tw-flex lg:tw-flex-col lg:tw-justify-center"
        @click="closeMenu"
      >
        <layers-icon class="tw-min-w-5" size="18"></layers-icon>
        <div class="menu__item-text lg:tw-mt-1 lg:tw-px-2 tw-text-center tw-font-medium tw-truncate tw-w-full">
          {{ $t('Products') }}
        </div>
      </div>
    </RouterLink>

    <!-- Partner orders / Кабинет партнера -->
    <RouterLink
      v-if="$can('edit', 'partner-order-read')"
      class="menu__item-container tw-relative tw-group"
      to="/partner-orders"
    >
      <div
        class="tw-cursor-pointer tw-gap-x-1 tw-items-center lg:tw-flex lg:tw-flex-col lg:tw-justify-center"
        @click="closeMenu"
      >
        <list-icon class="tw-min-w-5" size="18"></list-icon>
        <div class="menu__item-text lg:tw-mt-1 lg:tw-px-2 tw-text-center tw-font-medium tw-truncate tw-w-full">
          {{ $t('Exchange') }}
        </div>
      </div>
    </RouterLink>

    <!-- Counterparties / Контрагенты -->
    <div
      v-if="$can('edit', 'human-read') || $can('edit', 'organization-read')"
      class="menu__item-container tw-relative"
    >
      <div
        class="tw-cursor-pointer tw-gap-x-1 lg:tw-items-center tw-group tw-flex-col"
        @click="onChangePage('isUsersBlockVisible')"
      >
        <div
          :class="[isUsersBlockVisible ? 'router-link-active' : '']"
          class="tw-flex tw-gap-x-1 tw-items-center lg:tw-flex-col max-lg:tw-justify-start max-lg:tw-w-fit"
        >
          <users-icon class="tw-min-w-5" size="18"></users-icon>
          <div class="menu__item-text lg:tw-mt-1 lg:tw-px-2 tw-text-center tw-font-medium tw-truncate tw-w-full">
            {{ $t('Counterparties') }}
          </div>
          <svg
            :class="[isUsersBlockVisible ? 'tw-rotate-180 tw-fill-[#0db2b2]' : ' tw-fill-[#3E3E3E]']"
            class="tw-min-w-2 lg:tw-hidden"
            fill="none"
            height="4"
            viewBox="0 0 7 4"
            width="7"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.209636 0L6.04036 0C6.21525 0 6.3129 0.183973 6.2046 0.30957L3.28923 3.67769C3.20579 3.7741 3.0451 3.7741 2.96077 3.67769L0.0454025 0.30957C-0.0629029 0.183973 0.0347495 0 0.209636 0Z"
            />
          </svg>
        </div>

        <div
          :class="[isUsersBlockVisible ? 'tw-flex' : 'tw-hidden']"
          class="tw-gap-y-2.5 max-lg:tw-gap-y-4 tw-p-2 tw-top-0 tw-flex-col lg:tw-absolute max-lg:tw-p-0 max-lg:tw-pl-[31px] max-lg:tw-pt-4 tw-bg-[#f5f8fa] lg:tw-left-[80px] tw-z-[100] group-hover:lg:tw-flex tw-rounded-r-lg tw-min-w-[135px]"
        >
          <RouterLink
            v-if="$can('edit', 'human-read')"
            class="tw-relative tw-items-center tw-gap-x-2 hover:tw-text-[#0DB2B2] tw-transition"
            to="/counterparties/peoples"
          >
            <div class="tw-text-xs tw-cursor-pointer tw-font-medium" @click="closeMenu">
              {{ $t('Peoples') }}
            </div>
          </RouterLink>
          <RouterLink
            v-if="$can('edit', 'organization-read')"
            class="tw-relative tw-items-center tw-gap-x-2 hover:tw-text-[#0DB2B2] tw-transition"
            to="/counterparties/organizations"
          >
            <div class="tw-text-xs tw-cursor-pointer tw-font-medium" @click="closeMenu">
              {{ $t('Organizations') }}
            </div>
          </RouterLink>
        </div>
      </div>
    </div>

    <!-- Objects / Объекты -->
    <RouterLink v-if="$can('edit', 'base-object-read')" class="menu__item-container" to="/objects">
      <div
        class="tw-cursor-pointer tw-gap-x-1 tw-items-center lg:tw-flex lg:tw-flex-col lg:tw-justify-center"
        @click="closeMenu"
      >
        <map-pin-icon class="tw-min-w-5" size="18"></map-pin-icon>
        <div class="menu__item-text lg:tw-mt-1 lg:tw-px-2 tw-text-center tw-font-medium tw-truncate tw-w-full">
          {{ $t('Objects') }}
        </div>
      </div>
    </RouterLink>

    <!-- Orders / Заказы -->
    <RouterLink v-if="$can('edit', 'order-read')" class="menu__item-container" to="/orders">
      <div
        class="tw-cursor-pointer tw-gap-x-1 tw-items-center lg:tw-flex lg:tw-flex-col lg:tw-justify-center"
        @click="closeMenu"
      >
        <grid-icon class="tw-min-w-5" size="18"></grid-icon>
        <div class="menu__item-text lg:tw-mt-1 lg:tw-px-2 tw-text-center tw-font-medium tw-truncate tw-w-full">
          {{ $t('Orders') }}
        </div>
      </div>
    </RouterLink>

    <!-- Estimates / Сметы -->
    <RouterLink v-if="$can('edit', 'estimate-read')" class="menu__item-container tw-relative tw-group" to="/estimates">
      <div
        class="tw-cursor-pointer tw-gap-x-1 tw-items-center lg:tw-flex lg:tw-flex-col lg:tw-justify-center"
        @click="closeMenu"
      >
        <file-text-icon class="tw-min-w-5" size="18"></file-text-icon>
        <div class="menu__item-text lg:tw-mt-1 lg:tw-px-2 tw-text-center tw-font-medium tw-truncate tw-w-full">
          {{ $t('Estimates') }}
        </div>
      </div>
    </RouterLink>

    <!-- Events / Мероприятия -->
    <div v-if="$can('edit', 'event-read')" class="menu__item-container tw-relative">
      <div
        class="tw-cursor-pointer tw-gap-x-1 lg:tw-items-center tw-group tw-flex-col"
        @click="onChangePage('isEventsBlockVisible')"
      >
        <div
          :class="[isEventsBlockVisible ? 'router-link-active' : '']"
          class="tw-flex tw-gap-x-1 tw-items-center lg:tw-flex-col max-lg:tw-justify-start max-lg:tw-w-fit"
        >
          <i-event class="tw-min-w-5 tw-w-5"></i-event>
          <div class="menu__item-text lg:tw-mt-1 lg:tw-px-2 tw-text-center tw-font-medium lg:tw-truncate tw-w-full">
            {{ $t('Events') }}
          </div>
          <svg
            :class="[isEventsBlockVisible ? 'tw-rotate-180 tw-fill-[#0db2b2]' : ' tw-fill-[#3E3E3E]']"
            class="tw-min-w-2 lg:tw-hidden"
            fill="none"
            height="4"
            viewBox="0 0 7 4"
            width="7"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.209636 0L6.04036 0C6.21525 0 6.3129 0.183973 6.2046 0.30957L3.28923 3.67769C3.20579 3.7741 3.0451 3.7741 2.96077 3.67769L0.0454025 0.30957C-0.0629029 0.183973 0.0347495 0 0.209636 0Z"
            />
          </svg>
        </div>

        <div
          :class="[isEventsBlockVisible ? 'tw-flex' : 'tw-hidden']"
          class="tw-gap-y-2.5 max-lg:tw-gap-y-4 tw-p-2 tw-top-0 tw-flex-col lg:tw-absolute max-lg:tw-p-0 max-lg:tw-pl-[31px] max-lg:tw-pt-4 tw-bg-[#f5f8fa] lg:tw-left-[80px] tw-z-[100] group-hover:lg:tw-flex tw-rounded-r-lg tw-min-w-[135px]"
        >
          <RouterLink
            class="tw-relative tw-flex tw-items-center tw-gap-x-2 hover:tw-text-[#0DB2B2] tw-transition"
            to="/events"
          >
            <div class="tw-text-xs tw-cursor-pointer tw-font-medium" @click="closeMenu">
              {{ $t('All Events') }}
            </div>
          </RouterLink>
          <RouterLink
            class="tw-relative tw-items-center tw-gap-x-2 hover:tw-text-[#0DB2B2] tw-transition"
            to="/events?view=chart-orders"
          >
            <div class="tw-text-xs tw-cursor-pointer tw-font-medium" @click="closeMenu">
              {{ $t('Order schedule') }}
            </div>
          </RouterLink>
          <RouterLink
            class="tw-relative tw-items-center tw-gap-x-2 hover:tw-text-[#0DB2B2] tw-transition"
            to="/events?view=chart"
          >
            <div class="tw-text-xs tw-cursor-pointer tw-font-medium" @click="closeMenu">
              {{ $t('Employment schedule') }}
            </div>
          </RouterLink>
        </div>
      </div>
    </div>

    <!-- Files / Файлы -->
    <RouterLink class="menu__item-container" to="/files">
      <div
        class="tw-cursor-pointer tw-gap-x-1 tw-items-center lg:tw-flex lg:tw-flex-col lg:tw-justify-center"
        @click="closeMenu"
      >
        <i-open-folder class="tw-min-w-5 tw-size-[18px]"></i-open-folder>
        <div class="menu__item-text lg:tw-mt-1 lg:tw-px-2 tw-text-center tw-font-medium tw-truncate tw-w-full">
          {{ $t('Files') }}
        </div>
      </div>
    </RouterLink>

    <!-- Analytics / Аналитика -->
    <div class="menu__item-container tw-relative">
      <div
        class="tw-cursor-pointer tw-gap-x-1 lg:tw-items-center tw-group tw-flex-col"
        @click="onChangePage('isAnalyticsBlockVisible')"
      >
        <div
          :class="[isAnalyticsBlockVisible ? 'router-link-active' : '']"
          class="tw-flex tw-gap-x-1 tw-items-center lg:tw-flex-col max-lg:tw-justify-start max-lg:tw-w-fit"
        >
          <server-icon class="tw-min-w-5" size="18"></server-icon>
          <div class="menu__item-text lg:tw-mt-1 lg:tw-px-2 tw-text-center tw-font-medium lg:tw-truncate tw-w-full">
            {{ $t('Analytics') }}
          </div>
          <svg
            :class="[isAnalyticsBlockVisible ? 'tw-rotate-180 tw-fill-[#0db2b2]' : ' tw-fill-[#3E3E3E]']"
            class="tw-min-w-2 lg:tw-hidden"
            fill="none"
            height="4"
            viewBox="0 0 7 4"
            width="7"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.209636 0L6.04036 0C6.21525 0 6.3129 0.183973 6.2046 0.30957L3.28923 3.67769C3.20579 3.7741 3.0451 3.7741 2.96077 3.67769L0.0454025 0.30957C-0.0629029 0.183973 0.0347495 0 0.209636 0Z"
            />
          </svg>
        </div>

        <div
          :class="[isAnalyticsBlockVisible ? 'tw-flex' : 'tw-hidden']"
          class="tw-gap-y-2.5 max-lg:tw-gap-y-4 tw-p-2 tw-top-0 tw-flex-col lg:tw-absolute max-lg:tw-p-0 max-lg:tw-pl-[31px] max-lg:tw-pt-4 tw-bg-[#f5f8fa] lg:tw-left-[80px] tw-z-[100] group-hover:lg:tw-flex tw-rounded-r-lg tw-min-w-[135px]"
        >
          <RouterLink
            class="tw-relative tw-flex tw-items-center tw-gap-x-2 hover:tw-text-[#0DB2B2] tw-transition"
            to="/history"
          >
            <div class="tw-text-xs tw-cursor-pointer tw-font-medium" @click="closeMenu">
              {{ $t('History') }}
            </div>
          </RouterLink>
          <RouterLink
            v-if="$can('edit', 'vendor-response-log-read')"
            class="tw-relative tw-items-center tw-gap-x-2 hover:tw-text-[#0DB2B2] tw-transition"
            to="/logs"
          >
            <div class="tw-text-xs tw-cursor-pointer tw-font-medium" @click="closeMenu">
              {{ $t('Logs') }}
            </div>
          </RouterLink>
        </div>
      </div>
    </div>

    <!-- Directory / Справочник -->
    <RouterLink class="menu__item-container" to="/directory">
      <div
        class="tw-cursor-pointer tw-gap-x-1 tw-items-center lg:tw-flex lg:tw-flex-col lg:tw-justify-center"
        @click="closeMenu"
      >
        <database-icon class="tw-min-w-5" size="18"></database-icon>
        <div class="menu__item-text lg:tw-mt-1 lg:tw-px-2 tw-text-center tw-font-medium tw-truncate tw-w-full">
          {{ $t('Directory') }}
        </div>
      </div>
    </RouterLink>

    <!-- Settings / Настройки -->
    <div v-if="isShowSettings" class="menu__settings-container">
      <div
        class="tw-cursor-pointer tw-gap-x-1 lg:tw-items-center tw-group tw-flex-col max-lg:tw-items-start"
        @click="onChangePage('isSettingsBlockVisible')"
      >
        <div
          :class="[isSettingsBlockVisible ? 'tw-flex' : 'tw-hidden']"
          class="tw-gap-y-2.5 max-lg:tw-gap-y-4 tw-p-2 lg:tw-bottom-0 tw-flex-col lg:tw-absolute max-lg:tw-p-0 max-lg:tw-pl-[31px] max-lg:tw-pb-4 tw-bg-[#f5f8fa] lg:tw-left-[80px] tw-z-[100] group-hover:lg:tw-flex tw-rounded-r-lg tw-min-w-[135px]"
        >
          <div
            class="tw-relative tw-group tw-flex tw-items-center tw-gap-x-2 hover:tw-text-[#0DB2B2] tw-transition"
            @click="openSettings"
          >
            <div class="tw-text-xs tw-cursor-pointer tw-font-medium">Общие настройки</div>
          </div>
          <RouterLink
            :class="{'router-link-active ': '/users' === $route.path}"
            class="tw-relative tw-group tw-flex tw-items-center tw-gap-x-2 hover:tw-text-[#0DB2B2] tw-transition"
            to="/users"
          >
            <div class="tw-text-xs tw-cursor-pointer tw-font-medium" @click="closeMenu">
              {{ $t('Users') }}
            </div>
          </RouterLink>
          <RouterLink
            :class="{'router-link-active ': '/roles' === $route.path}"
            class="tw-relative tw-group tw-flex tw-items-center tw-gap-x-2 hover:tw-text-[#0DB2B2] tw-transition"
            to="/roles"
          >
            <div class="tw-text-xs tw-cursor-pointer tw-font-medium" @click="closeMenu">
              {{ $t('Roles') }}
            </div>
          </RouterLink>
        </div>

        <div
          :class="[isSettingsBlockVisible ? 'router-link-active' : '']"
          class="tw-flex tw-gap-x-1 tw-items-center lg:tw-flex-col max-lg:tw-justify-start max-lg:tw-w-fit tw-group"
        >
          <settings-icon class="tw-min-w-5" size="18"></settings-icon>
          <div class="menu__item-text lg:tw-mt-1 lg:tw-px-2 tw-text-center tw-font-medium lg:tw-truncate tw-w-full">
            {{ $t('Settings') }}
          </div>
          <svg
            :class="[isSettingsBlockVisible ? 'tw-rotate-180 tw-fill-[#0db2b2]' : 'tw-fill-[#3E3E3E]']"
            class="tw-min-w-2 lg:tw-hidden group-hover:tw-fill-[#0db2b2]"
            fill="none"
            height="4"
            viewBox="0 0 7 4"
            width="7"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.209636 0L6.04036 0C6.21525 0 6.3129 0.183973 6.2046 0.30957L3.28923 3.67769C3.20579 3.7741 3.0451 3.7741 2.96077 3.67769L0.0454025 0.30957C-0.0629029 0.183973 0.0347495 0 0.209636 0Z"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  AlertTriangleIcon,
  ClockIcon,
  DatabaseIcon,
  FileTextIcon,
  GridIcon,
  HomeIcon,
  LayersIcon,
  ListIcon,
  LockIcon,
  MapPinIcon,
  ServerIcon,
  SettingsIcon,
  UserCheckIcon,
  UsersIcon,
} from 'vue-feather-icons'
import IEvent from '@/components/icons/iEvent.vue'
import IOpenFolder from '@/components/icons/iOpenFolder.vue'

export default {
  components: {
    IOpenFolder,
    IEvent,
    VuePerfectScrollbar,
    HomeIcon,
    UsersIcon,
    FileTextIcon,
    LayersIcon,
    DatabaseIcon,
    LockIcon,
    SettingsIcon,
    UserCheckIcon,
    ListIcon,
    MapPinIcon,
    GridIcon,
    ClockIcon,
    AlertTriangleIcon,
    ServerIcon,
  },

  name: 'Menu',

  computed: {
    isShowSettings() {
      return this.$store.state.user.role === 'admin'
    },
    profile() {
      return this.$store.state.user.profile
    },
    showMenu() {
      return this.$store.state.other.showDesktopMenu
    },
    windowWidth() {
      return this.$store.state.other.windowWidth
    },
  },

  data() {
    return {
      isUsersBlockVisible: false,
      isEventsBlockVisible: false,
      isAnalyticsBlockVisible: false,
      isSettingsBlockVisible: false,
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      vcoConfig: {
        handler: this.onClickOutside,
        middleware: this.middleware,
        events: ['dblclick', 'click'],
        isActive: true,
        detectIFrame: true,
        capture: false,
      },
    }
  },

  methods: {
    closeMenu() {
      this.$store.commit('other/changeMobileMenu', false)
    },
    openSettings() {
      this.$store.commit('profile/showProfile', true)
      this.$store.commit('profile/showSyncAmo', false)
      this.$store.commit('profile/updateSettings', 'settings')
      this.closeMenu()
    },
    openUserMenu() {
      this.$store.commit('profile/showProfile', true)
      this.$store.commit('profile/updateSettings', 'profile')
    },
    onClickOutside() {
      this.$store.commit('profile/showProfile', false)
    },
    middleware(event) {
      return event.target.className !== 'modal'
    },
    onChangePage(page) {
      if (this.windowWidth < 768) {
        this.$data[page] = !this.$data[page]
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.menu {
  background-color: #f5f8fa;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #3e3e3e;
  //position: relative;
  //z-index: 10;

  &__user-block {
    position: fixed;
    display: flex;
    left: 65px;
    top: 5px;
    background-color: var(--white);
    border: 1px solid var(--iron);
    width: 370px;
    padding: 10px;
    border-radius: 2px;
    justify-content: flex-start;
    align-items: center;
    z-index: 10;
  }

  &__user-container {
    display: flex;
    width: 100%;
  }

  &__image-container {
    margin-right: 12px;
  }

  &__image {
    width: 48px;
    border-radius: 100px;
  }

  &__data-block {
    text-align: left;
    cursor: pointer;
  }

  &__user-name {
    font-size: 15px;
    line-height: 18px;
    color: var(--mine-shaft);
  }

  &__user-info {
    font-size: 12px;
    line-height: 130%;
    color: var(--gull-gray);

    &--email {
      color: var(--accent);
    }
  }

  &__logout-block {
    cursor: pointer;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__start-btn {
    background: url('../assets/logo.png') no-repeat 50%;
    background-size: 30px;
    width: 30px;
    min-height: 31px;
    align-self: center;
    margin-top: 24px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  &__item-container {
    cursor: pointer;
    justify-content: center;
    align-content: center;
    align-items: center;
    align-self: center;
    text-decoration: none;
    color: var(--mine-shaft);
    width: 80px;

    svg {
      color: #5f5f5f;
    }

    &.router-link-active,
    .router-link-active {
      svg {
        color: var(--accent);
      }

      .menu__item-text {
        color: var(--accent);
      }
    }

    &:hover {
      svg {
        color: var(--accent);
      }

      .menu__item-text {
        color: var(--accent);
      }
    }

    &.router-link-active.disabled-hover {
      svg {
        color: var(--mine-shaft);
      }

      .menu__item-text {
        color: var(--mine-shaft);
      }
    }
  }

  &__settings-container {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;

    > div {
      cursor: pointer;
      width: 100%;
      text-align: center;
      padding-bottom: 20px;
      padding-top: 20px;
      border-top: 1px solid #e0e0e0;

      .menu__item-text {
        display: none;
      }

      .router-link-active {
        svg {
          color: var(--accent);
        }

        .menu__item-text {
          color: var(--accent);
        }
      }

      svg {
        color: #5f5f5f;
      }

      &:hover {
        svg {
          color: var(--accent);
        }

        .menu__item-text {
          color: var(--accent);
        }
      }
    }
  }

  &__item-icon {
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    &--settings {
      width: 20px;
      height: 20px;
      margin: 30px auto;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &__item-text {
    font-size: 11px;
    line-height: 13px;
    margin: 0 7px 0 7px;
    cursor: pointer;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ps-container {
  overflow: unset !important;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .menu {
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 0;
    overflow-y: auto;

    .ps-container {
      overflow: unset !important;
      width: 100%;
    }

    &__start-btn {
      margin-top: 0;
      margin-left: 0;
      align-items: flex-start;
    }

    &__settings-container {
      margin: 0;
      width: auto;
      align-self: flex-start;

      > div {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 10px 5px 10px 20px;
        border-top: 0;

        .menu__item-icon {
          display: flex;
          text-align: center;
          margin: 0;
          width: 25px;
        }

        .menu__item-text {
          display: flex;
        }
      }

      .menu__item-icon--settings {
        margin: 0;
      }
    }

    &__item-container {
      margin: 0;
      width: auto;
      align-self: flex-start;

      > div {
        padding-left: 20px;
        display: flex;

        .menu__item-icon {
          display: flex;
          text-align: center;
          margin: 0;
          width: 25px;

          svg {
            height: 20px;
            width: 20px;
          }
        }

        .menu__item-text {
          font-size: 12px;
        }
      }
    }

    &__user-block {
      left: 5px;
      top: 55px;
      width: 300px;
    }

    &__item-text {
      white-space: unset;
      overflow: unset;
      text-overflow: unset;
    }
  }
}
</style>
