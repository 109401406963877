<template>
    <div class="zem-tabs">
        <div class="zem-tabs__tabs-container">
            <div v-for="(item, index) in tabs"
                 :key="index"
                 :class="tabClass(item)"
                 class="zem-tabs__tab"
                 @click="onClickTab(item)">
                {{ item.name }}
            </div>
        </div>
        <div class="zem-tabs__body">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ZemTabs',

    props: {
        tabs: {
            type: Array,
            default: () => ([])
        },
        selectedTab: {
            type: String,
            default: 'Настройки'
        }
    },

    computed: {},

    methods: {
        onClickTab(item) {
            this.$emit('select', item.name);
        },
        tabClass(item) {
            if (this.selectedTab === item.name) {
                return 'zem-tabs__tab--selected';
            }
            return ''
        }
    }
};
</script>

<style lang="scss" scoped>
.zem-tabs {
    height: 100%;
    position: relative;
    display: block;
    padding: 20px 25px;

    &__tabs-container {
        display: flex;
        overflow-x: auto;
    }

    &__tab {
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: #3E3E3E;
        cursor: pointer;
        margin-bottom: 10px;

        &--selected {
            background-color: #fff;
            color: $color-accent;
        }
    }

    &__tab:not(:first-child) {
        margin-left: 24px;
    }

    &__body {
        width: 100%;
    }
}
</style>
