import Vue from "vue"
import App from "./App.vue"
import store from "./store"
import {router} from "./router"

import "@/libs/toastification"
import '@/libs/fontawesome'
import '@/libs/tippy'
import '@/libs/tree-element-ui'
import '@/libs/vee-validate'
import '@/libs/vuescroll'
import '@/libs/good-table'
import '@/libs/v-select'
import '@/libs/acl'
import i18n from '@/libs/i18n'

import VueCompositionAPI from '@vue/composition-api'

Vue.use(VueCompositionAPI)

export const eventBus = new Vue()

// import setupInterceptors from "@/services/setupInterceptors";
// setupInterceptors(store);

Vue.config.productionTip = false

// import assets styles
require("@/assets/scss/styles.scss")
require('@/assets/scss/tailwind.scss')
// eslint-disable-next-line no-undef
require("@/assets/scss/variables.scss")

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount("#app")
