/* import the fontawesome core */
import {library} from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {
    faBasketShopping, faCalculator, faFileLines,
    faFolderTree,
    faGear,
    faHome,
    faLayerGroup, faListCheck,
    faLocationDot, faTableList,
    faUserGroup, faUserLock,
    faUsersGear, faXmark
} from '@fortawesome/free-solid-svg-icons'
import Vue from "vue";

/* add icons to the library */
library.add(
    faHome,
    faUserGroup,
    faUsersGear,
    faGear,
    faLayerGroup,
    faFileLines,
    faFolderTree,
    faLocationDot,
    faBasketShopping,
    faListCheck,
    faTableList,
    faXmark,
    faCalculator,
    faUserLock
)

/* add font awesome icon component */
Vue.component('font-ai', FontAwesomeIcon)