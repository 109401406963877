<template>
  <img
    :src="require(`@/assets/icons/Spinner-1s-150px${color === 'light' ? '-white' : ''}.svg`)"
    :style="{'min-width': size + 'px', width: size + 'px', 'min-height': size + 'px', height: size + 'px'}"
    alt=""
    class="zem-preloader"
  />
</template>

<script>
export default {
  props: {
    size: {
      type: [String, Number],
      default: 14,
    },
    color: {
      type: String,
      default: 'light',
    },
  },

  data() {
    return {}
  },
}
</script>
