import qs from 'qs'

import $api from '@/helpers/config-axios'
import authHeader from '@/services/auth-header'

class AuthService {
  login(user) {
    return $api.post('clients/web/login', {
      email: user.email,
      password: user.password,
    })
  }

  refresh(oldToken) {
    return $api.post('clients/web/refresh', {
      refresh_token: oldToken,
    })
  }

  register(user) {
    return $api.post(
      'register',
      qs.stringify({
        email: user.email,
        password: user.password,
        name: user.name,
      })
    )
  }

  registerUser(user) {
    return $api.post(
      'users',
      user,
      {
        headers: authHeader(),
      }
    )
  }

  sentEmail(user) {
    return $api.post('password/forgot', {
      email: user.email,
      reseturl: 'password-reset',
    })
  }

  resetPassword(body) {
    return $api.post('password/reset', body)
  }
}

export default new AuthService()
