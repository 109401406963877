import Vue from "vue";
import vuescroll from "vuescroll";


Vue.use(vuescroll, {
    ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {
            background: "#0db2b2",
            size: "5px",
            minSize: 0.4,
            keepShow: true,
            opacity: 0.75,
        },
    },
    name: "zem-scroll",
})