<template>
  <div id="app" class="tw-h-dvh">
    <Layout>
      <Menu
        slot="menu"
        :isUserMenuVisible="isUserMenuVisible"
        @openProfile="onOpenProfile"
        @openUserMenu="onOpenUserMenu"
      />

      <Page v-if="$route.meta['access'] === '' || $can('edit', $route.meta['access'])" slot="body" />

      <Redirect v-else slot="body" />

      <SettingsPage v-if="$store.state.profile.show" slot="sidebar" />

      <SyncAMO v-if="$store.state.profile.showSyncAmo" slot="sidebar" />
    </Layout>
  </div>
</template>

<script>
import DefaultLayout from './layouts/DefaultLayout'
import Menu from './components/Menu'
import Page from './components/Page'
import Vue from 'vue'
import vClickOutside from 'v-click-outside'
import SidebarLeft from './components/SidebarLeft'
import Tabs from './components/ui/Tabs'
import UserCard from './components/UserCard'
import CollapseCard from './components/CollapseCard'
import Security from './views/Settings/SettingsSecurity'
import Settings from './views/Settings/SettingsAmoCrm'
import SidebarRight from '@/components/SidebarRight'
import SettingsPage from '@/views/Settings/Settings'
import Redirect from '@/views/Redirect.vue'
import SyncAMO from '@/views/SyncAMO.vue'

Vue.use(vClickOutside)

export default {
  name: 'App',
  components: {
    SyncAMO,
    Redirect,
    SidebarRight,
    Layout: DefaultLayout,
    Menu,
    Page,
    SidebarLeft,
    Tabs,
    UserCard,
    CollapseCard,
    Security,
    Settings,
    SettingsPage,
  },
  data() {
    return {
      isLeftSidebarVisible: false,
      isUserMenuVisible: false,
    }
  },
  created() {
    this.onResize()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  methods: {
    onResize() {
      this.$store.commit('other/UPDATE_WINDOW_WIDTH', window.innerWidth)
    },
    onOpenProfile(data) {
      this.isLeftSidebarVisible = !this.isLeftSidebarVisible
      this.isUserMenuVisible = false
      this.selectedTab = data.name
    },
    onOpenUserMenu(data) {
      this.isUserMenuVisible = data
    },
  },
}
</script>

<style lang="scss">
#app {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

textarea {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
}
</style>