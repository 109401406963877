export const objects = {
  namespaced: true,

  state: {
    elements: [],
    selectedObjects: [],
    isGetAllObjectsSuccess: false,
    objectsPagination: {
      custom: {
        total: null,
      },
      pagination: {
        current_page: null,
        total_pages: null,
        total: null,
      },
    },
    selectedItemsForDelete: [],
    paginationPage: 1,
    filter: {},
    orderBy: null,
    sortedBy: null,
    search: '',
    isActive: null,
    isArchive: false,
    searchCount: 0,
  },

  mutations: {
    getAllObjects(state, objects) {
      state.isGetAllObjectsSuccess = true
      state.elements = objects.data
      state.objectsPagination = objects.meta
    },
    changePagination(state) {
      state.objectsPagination = state
    },
    getSelectedObjects(state, objects) {
      state.selectedObjects = objects.data
    },
    sorting(state, data) {
      state.sortedBy = data
    },
    ordering(state, data) {
      state.orderBy = data
    },
    filterMain(state, data) {
      state.filter['main'] = data
    },
    itemsForDelete(state, data) {
      state.selectedItemsForDelete = data
    },
    getSearch(state, data) {
      state.search = data
    },
    changePage(state, data) {
      state.paginationPage = data
    },
    changeIsActive(state, data) {
      state.isActive = data
    },
    changeIsArchive(state, data) {
      state.isArchive = data
    },
    getSearchCount(state, data) {
      state.searchCount = data
    },
  },

  actions: {},
}
