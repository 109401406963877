<template>
  <div class="settings">
    <div class="settings__container">
      <div class="settings__image-block">{{ $t('Security') }}</div>

      <div class="settings__inputs-block">
        <zem-input v-model="oldPassword" :max-length="255" :placeholder="$t('Old password')" type="password" />
        <zem-input v-model="newPassword" :max-length="255" :placeholder="$t('New password')" type="password" />
        <zem-input v-model="repeatPassword" :max-length="255" :placeholder="$t('Repeat password')" type="password" />

        <zem-button @click="checkedPassword">
          <img :src="require('@/assets/icons/check.svg')" alt="" class="mr-1" />
          {{ $t('Save') }}
        </zem-button>
      </div>
    </div>
  </div>
</template>

<script>
import ZemInput from '@/components/ui/ZemInput.vue'
import ZemButton from '@/components/ui/ZemButton.vue'
import OtherService from '@/services/other.service'
import UsersService from '@/services/users.service'
import {errorHandler} from '@/assets/scripts/scripts'

export default {
  name: 'Security',
  components: {
    ZemInput,
    ZemButton,
  },
  data() {
    return {
      checkExchange: true,
      oldPassword: '',
      newPassword: '',
      repeatPassword: '',
    }
  },

  methods: {
    checkedPassword() {
      if (this.oldPassword === '' || this.newPassword === '' || this.repeatPassword === '') {
        this.$toast.error('Необходимо заполнить все поля')
      } else if (this.oldPassword === this.newPassword) {
        this.$toast.error('Старый и новый пароль совпадают')
      } else if (this.newPassword !== this.repeatPassword) {
        this.$toast.error('Новый пароль не совпадает')
      } else {
        OtherService.checkPassword({
          password: this.oldPassword,
        }).then(r => {
          if (r.status === 200) {
            UsersService.updateUser(this.$store.state.user.profile.id, {password: this.newPassword}, true)
              .then(r => {
                if (r.status === 200) this.$toast.success('Новый пароль изменен')
              })
              .catch(e => {
                errorHandler(e)
              })
          } else if (r.status === 204) {
            this.$toast.error('Вы ввели неверный пароль')
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.settings {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  align-self: center;
  align-content: center;
  height: 100%;
  text-align: left;

  &__container {
    //padding: 0 25px;
    width: 470px;
  }

  &__label {
    font-size: 14px;
    line-height: 16px;
    color: $color-black;
    text-align: left;
    display: flex;
    margin-bottom: 2px;
  }

  &__image-block {
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    display: flex;
    color: $color-mine-shaft;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 22px;
  }

  &__inputs-block {
    width: 190px;

    label {
      margin-top: 12px;
    }

    button {
      margin-top: 22px;
    }
  }
}
</style>
