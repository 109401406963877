<template>
    <div v-click-outside="vcoConfig" class="zem-select">
        <div class="zem-select--wrapper">
            <div class="zem-select__selected" @click="openOptions">
                {{ selectedValue }}
            </div>
            <zem-button class="zem-select__button" @click="onSelected">+</zem-button>
        </div>
        <div :class="optionsVisibilityClass" class="zem-select__options--wrapper">
            <div v-for="(option, idx) in values" :key="`${idx}-option`" class="zem-select__options"
                 @click="selectOption(option)">
                {{ option }}
            </div>
        </div>
    </div>
</template>

<script>
import ZemButton from '@/components/ui/ZemButton'

export default {
    name: 'zem-select',
    components: {
        ZemButton,
    },
    data() {
        return {
            selectedValue: '',
            isOptionsVisible: false,
            vcoConfig: {
                handler: this.onClickOutside,
                // middleware: this.middleware,
                events: ['dblclick', 'click'],
                // Note: The default value is true, but in case you want to activate / deactivate
                //       this directive dynamically use this attribute.
                isActive: true,
                // Note: The default value is true. See "Detecting Iframe Clicks" section
                //       to understand why this behaviour is behind a flag.
                detectIFrame: true,
                // Note: The default value is false. Sets the capture option for EventTarget addEventListener method.
                //       Could be useful if some event's handler calls stopPropagation method preventing event bubbling.
                capture: false
            }
        }
    },
    props: {
        values: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        openOptions() {
            if (this.values.length) {
                this.isOptionsVisible = !this.isOptionsVisible
            }
        },
        selectOption(option) {
            this.selectedValue = option
            this.isOptionsVisible = !this.isOptionsVisible
        },
        onSelected() {
            if (this.selectedValue && this.selectedValue !== "<No Data Available>") {
                this.$emit('on-selected', this.selectedValue)
            }
        },
        onClickOutside() {
            this.isOptionsVisible = false
        }
    },
    computed: {
        optionsVisibilityClass() {
            return !this.isOptionsVisible ? "zem-select__options--wrapper--hide" : ''
        }
    },
    mounted() {
        this.selectedValue = this.values[0]
    },
    watch: {
        values(newVal) {
            if (newVal.length) {
                this.selectedValue = newVal[0]
            } else {
                this.selectedValue = "<No Data Available>"
                this.isOptionsVisible = false
            }

        }
    }
}
</script>

<style lang="scss" scoped>
.zem-select {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 7px;
    color: $color-mine-shaft;
    line-height: 160%;
    transition: all 0.25s;
    user-select: none;
    cursor: pointer;
    overflow: hidden;

    &--wrapper {
        display: flex;
        flex-direction: row;
    }

    &__selected {
        width: 200px;
        height: 20px;
        border: 2px solid $color-riptide;
        border-radius: 4px;
        padding: 0 0 0 5px;
        transition: all 0.25s;

        &:hover {
            border-color: $color-accent;
        }
    }

    &__options {
        padding: 5px;
        transition: all 0.25s;

        &:hover {
            background-color: $color-aqua-island;
        }

        &--wrapper {
            display: block;
            position: absolute;
            width: 205px;
            max-height: 200px;
            z-index: 10;
            margin: 24px 0 0 0;
            box-sizing: content-box;
            border: 2px solid $color-riptide;
            border-radius: 4px;
            background-color: $color-athens-gray;
            overflow: auto;
            transition: all 0.1s;

            &:hover {
                border-color: $color-accent;
            }

            &--hide {
                max-height: 0;
                border: none;
            }
        }
    }

    &__button {
        padding: 0;
        margin: 0 0 0 5px;
        width: 30px;
        height: 23px;
    }
}
</style>
