<template>
  <div class="mt-2">
    <UserCard
      :info="itemInfo"
      :status-edit="editProfile"
      @save="updateProfile"
      @on-edit="editProfile = !editProfile"
      @on-cancel="cancelProfile"
    />
    <validation-observer ref="profileForm">
      <zem-collapse v-for="(item, idx) in fields" :key="idx" :title="$t('Contacts')">
        <div class="zem-collapse-table rows">
          <div v-for="(field, f_idx) in item.data" :key="f_idx" class="zem-collapse-table__row">
            <div class="zem-collapse-table__column title">
              {{ field.name }}<span v-if="editProfile" class="required-field">*</span>
            </div>
            <div v-if="!editProfile" class="zem-collapse-table__column text">
              {{ field.value }}
            </div>
            <div v-else-if="editProfile && field.type === 'phone'" class="zem-collapse-table__column text">
              <ValidationProvider
                v-slot="{errors}"
                :name="field.name"
                :rules="{
                  required: true,
                  regex: `^[\\+]?[7]?[8]?\\s[(][0-9]{3}[)]\\s[0-9]{3}[-\\s\\.]?[0-9]{2}[-\\s\\.]?[0-9]{2}$`,
                }"
                class="w-100"
              >
                <ZemInput
                  v-model="field.value"
                  :errors="errorsBackend"
                  class="m-0 mt-0"
                  error-title="client_phone"
                  mask="phone"
                  placeholder="+71234567890"
                  type="tel"
                  @input="errorsBackend = []"
                  @update-phone="field.value = $event"
                />
                <p v-show="errors.length !== 0" class="details-form__data-block__error">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </div>
            <div v-else-if="editProfile && field.type === 'email'" class="zem-collapse-table__column text">
              <ValidationProvider
                v-slot="{errors}"
                :name="field.name"
                :rules="{
                  required: true,
                  email: true,
                }"
                class="w-100"
              >
                <ZemInput v-model="field.value" :placeholder="field.name" class="m-0" isCompact type="email" />
                <p v-show="errors.length !== 0" class="details-form__data-block__error">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </div>
            <div v-else class="zem-collapse-table__column text">
              <ZemInput v-model="field.value" :placeholder="field.name" class="m-0" isCompact type="text" />
            </div>
          </div>
        </div>
      </zem-collapse>
    </validation-observer>
  </div>
</template>

<script>
import UserCard from '@/components/UserCard'
import UserService from '@/services/user.service'
import ZemCollapse from '@/components/ui/ZemCollapse'
import ZemInput from '@/components/ui/ZemInput'
import {formattedPhone, phoneTrim} from '@/assets/scripts/scripts'
import toastMessage from '@/helpers/messages'

export default {
  components: {
    UserCard,
    ZemCollapse,
    ZemInput,
  },
  data() {
    return {
      itemInfo: {
        type: 'people',
        name: '',
        phone: '',
        email: '',
        telegram: '',
        id: '',
        status: '',
        img: require('@/assets/images/user.webp'),
      },
      fields: [],
      editProfile: false,
      currentFields: [],
      errorsBackend: [],
    }
  },
  computed: {
    profile() {
      return this.$store.state.user.profile
    },
  },
  watch: {
    profile() {
      this.getUserData()
    },
  },
  mounted() {
    this.getUserData()
  },
  methods: {
    getUserData() {
      const phone = this.profile.human.data.phone

      this.itemInfo = {
        type: 'people',
        name: this.profile.human.data.fio,
        phone: formattedPhone(phone),
        email: this.profile.email,
        telegram: '8 912 123 45 67',
        id: this.profile.human.data.number,
        status: 'Активный',
        img: require('@/assets/images/user.webp'),
      }

      this.fields = [
        {
          title: 'Контакты',
          data: [
            {
              name: 'ФИО',
              value: this.profile.human.data.fio,
              new: false,
              type: 'text',
              read: true,
            },
            {
              name: 'Телефон',
              value: formattedPhone(phone),
              type: 'phone',
              new: false,
              read: true,
            },
            {
              name: 'Email',
              value: this.profile.email,
              type: 'email',
              new: false,
              read: true,
            },
          ],
          possibleToCreate: [
            {
              name: 'Контакт',
            },
          ],
        },
      ]
    },
    updateProfile() {
      this.$refs.profileForm.validate().then(success => {
        if (success) {
          UserService.updateProfile({
            fio: this.fields[0].data[0].value,
            phone: phoneTrim(this.fields[0].data[1].value),
            email: this.fields[0].data[2].value,
          })
            .then(r => {
              this.$toast.success(toastMessage.updatePeople)
            })
            .catch(e => {
              if (e.request.status === 417) {
                this.$toast.error(e.response.data.message)
              } else {
                this.$toast.error(JSON.parse(e.request.responseText).message)
              }
            })
        } else {
          this.$toast.error(toastMessage.errorValidForm)
        }
      })
    },
    cancelProfile() {
      this.editProfile = !this.editProfile
      this.getUserData()
    },
  },
}
</script>

<style lang="scss" scoped></style>
