const toastMessage = {
  errorValidForm: 'Заполните все обязательные поля и проверьте корректность данных!',
  authSuccess: 'Авторизация выполнена',
  createOrganization: 'Организация создана!',
  updateOrganization: 'Организация обновлена!',
  deleteOrganization: 'Организация удалена!',
  createPeople: 'Человек создан!',
  updatePeople: 'Человек обновлен!',
  deletePeople: 'Человек удален!',
  updateProfile: 'Профиль обновлен!',
  updateKeyAmoCrm: 'Ключ AMO CRM обновлен!',
  createOrder: 'Заявка создана!',
  deleteOrder: 'Заявка удалена!',
  editOrder: 'Заявка отредактирована!',
  errorPhoneOrder: 'Заявка с таким телефоном уже существует!',
  takeAllOrders: 'Заявки взяты в работу!',
  takeSuccessAllOrders: 'Все доступные заявки взяты в работу!',
  archiveOrders: 'Заявки в архиве!',
  deleteOrders: 'Заявки удалены!',
  closeOrders: 'Заявка успешно закрыта!',
  errorCloseOrder: 'Необходимо указать фактический бюджет!',
  closeNegativeOrders: 'Заявка закрыта и не реализована!',
  errorActiveOrder: 'Заявка уже взята другим пользователем!',
  errorActiveOrders: 'Некоторые заявки уже взяты другими пользователями!',
  errorActiveEditOrder: 'Заявка уже взята другим пользователем в работу, редактирование невозможно!',
  connectingUserToCompany: 'Пользователь прикреплен к организации!',
  editBudgetFinish: 'Фактический бюджет изменен!',
  copyProduct: 'Продукт скопирован!',
  updateProduct: 'Продукт обновлен!',
}

export default toastMessage
