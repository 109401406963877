<template>
  <div class="mt-2">
    <UserCard
      v-if="profile.human.data.organizations.data.length !== 0"
      :info="companyInfo"
      :status-edit="editCompany"
      @save="updateCompany"
      @on-edit="editCompany = !editCompany"
      @on-cancel="cancelCompany"
    />
    <div
      v-if="profile.human.data.organizations.data.length === 0 && !showFieldsNewCompany"
      class="colored colored--link"
      @click="showFieldsNewCompany = !showFieldsNewCompany"
    >
      + {{ $t('Organization') }}
    </div>
    <div v-if="showFieldsNewCompany" class="new-company">
      <template v-for="(item, idx) in newCompanyFields">
        <div
          v-if="$store.state.profile.companiesSearch.length === 0 || item.name === 'ИНН'"
          :key="idx"
          class="new-company__row"
        >
          <div class="new-company__column title required-field">{{ item.name }}</div>
          <div v-if="item.name === 'Страна'" class="new-company__input">
            <ZemDropdownList
              :default="'Страна'"
              :options="$store.state.profile.countries"
              :placeholder="'Страна'"
              :value="country['title']"
              class="select m-0"
              item="name"
              type="object"
              @input="country = $event"
            />
          </div>
          <div v-else-if="item.name === 'Регион'" class="new-company__input">
            <ZemDropdownList
              :default="'Регион'"
              :options="$store.state.profile.regions"
              :placeholder="'Регион'"
              :value="region['title']"
              class="select m-0"
              item="name"
              type="object"
              @input="region = $event"
            />
          </div>
          <div v-else-if="item.name === 'Город'" class="new-company__input">
            <ZemDropdownList
              :default="'Город'"
              :options="$store.state.profile.cities"
              :placeholder="'Город'"
              :value="city['title']"
              class="select m-0"
              item="name"
              type="object"
              @input="city = $event"
            />
          </div>
          <div v-else-if="item.name === 'Тип организации'" class="new-company__input">
            <ZemDropdownList
              :default="'Тип организации'"
              :options="$store.state.profile.ownership"
              :placeholder="'Тип организации'"
              :value="ownership['title']"
              class="select m-0"
              item="name"
              type="object"
              @input="ownership = $event"
            />
          </div>
          <div v-else-if="item.name === 'ИНН'" class="new-company__input">
            <ZemInput
              v-model="item.value"
              :placeholder="item.name"
              class="m-0"
              isCompact
              type="text"
              @input="getCompanies"
            />
            <p v-if="$store.state.profile.companiesSearch.length > 0" class="new-company__input__error">
              Компания уже существует.
            </p>
            <zem-button
              v-if="$store.state.profile.companiesSearch.length > 0"
              class="mt-1"
              @click="connectingUserToCompany"
            >
              Присоединиться
            </zem-button>
          </div>
          <div v-else class="new-company__input">
            <ZemInput v-model="item.value" :placeholder="item.name" class="m-0" isCompact type="text" />
          </div>
        </div>
      </template>
      <div class="d-flex mt-4">
        <zem-button v-if="$store.state.profile.companiesSearch.length === 0" @click="createCompany">
          <img :src="require('@/assets/icons/check.svg')" alt="" class="mr-1" />
          {{ $t('Save') }}
        </zem-button>
        <zem-link class="ml-3" @click="showFieldsNewCompany = !showFieldsNewCompany">{{ $t('Cancel') }}</zem-link>
      </div>
    </div>
    <template v-if="profile.human.data.organizations.data.length !== 0">
      <validation-observer ref="companyForm" class="w-100">
        <zem-collapse v-for="(item, idx) in companiesFields" :key="idx" title="Контакты">
          <div class="zem-collapse-table">
            <div v-for="(field, f_idx) in item.data" :key="f_idx" class="zem-collapse-table__row">
              <div class="zem-collapse-table__column title">
                {{ field.name }}
              </div>
              <div v-if="!editCompany" class="zem-collapse-table__column text">
                {{ field.value }}
              </div>
              <div v-else-if="editCompany && field.type === 'phone'" class="zem-collapse-table__column text">
                <ValidationProvider
                  v-slot="{errors}"
                  :name="field.name"
                  :rules="{
                    required: true,
                    regex: `^[\\+]?[7]?[8]?\\s[(][0-9]{3}[)]\\s[0-9]{3}[-\\s\\.]?[0-9]{2}[-\\s\\.]?[0-9]{2}$`,
                  }"
                  class="w-100"
                >
                  <ZemInput
                    v-model="field.value"
                    :placeholder="field.name"
                    class="m-0"
                    isCompact
                    mask="phone"
                    type="tel"
                  />
                  <p v-show="errors.length !== 0" class="details-form__data-block__error">
                    {{ errors[0] }}
                  </p>
                </ValidationProvider>
              </div>
              <div v-else-if="editCompany && field.type === 'email'" class="zem-collapse-table__column text">
                <ValidationProvider
                  v-slot="{errors}"
                  :name="field.name"
                  :rules="{
                    required: true,
                    email: true,
                  }"
                  class="w-100"
                >
                  <ZemInput v-model="field.value" :placeholder="field.name" class="m-0" isCompact type="email" />
                  <p v-show="errors.length !== 0" class="details-form__data-block__error">
                    {{ errors[0] }}
                  </p>
                </ValidationProvider>
              </div>
              <div v-else class="zem-collapse-table__column text">
                <ZemInput v-model="field.value" :placeholder="field.name" class="m-0" isCompact type="text" />
              </div>
            </div>
          </div>
        </zem-collapse>
      </validation-observer>
    </template>
  </div>
</template>

<script>
import UserCard from '@/components/UserCard'
import ZemButton from '@/components/ui/ZemButton'
import CollapseCard from '@/components/CollapseCard'
import ZemInput from '@/components/ui/ZemInput'
import ProfileService from '@/services/profile.service'
import UserService from '@/services/user.service'
import ZemLink from '@/components/ui/ZemLink'
import ZemCollapse from '@/components/ui/ZemCollapse'
import toastMessage from '@/helpers/messages'
import messages from '@/helpers/messages'
import peopleAndCompaniesService from '@/services/peopleAndCompanies.service'
import PeopleAndCompaniesService from '@/services/peopleAndCompanies.service'
import {errorHandler, formattedPhone, phoneTrim} from '@/assets/scripts/scripts'
import ZemDropdownList from '@/components/ui/ZemDropdownList.vue'

export default {
  components: {
    UserCard,
    ZemDropdownList,
    ZemButton,
    CollapseCard,
    ZemInput,
    ZemLink,
    ZemCollapse,
  },
  data() {
    return {
      companyInfo: {
        type: 'company',
        name: 'Иванов Сергей Петрович',
        id: '232323',
        status: 'Активный',
      },
      newCompanyFields: [
        {
          name: 'ИНН',
          value: '',
        },
        {
          name: 'Название',
          value: '',
        },
        {
          name: 'Страна',
          value: {
            id: 'NxOpZowo9GmjKqdR',
            title: 'Россия',
          },
        },
        {
          name: 'Регион',
          value: {
            id: 'NxOpZowo9GmjKqdR',
            title: 'Москва и МО',
          },
        },
        {
          name: 'Город',
          value: {
            id: 'NxOpZowo9GmjKqdR',
            title: 'г. Москва',
          },
        },
        {
          name: 'Тип организации',
          value: '',
        },
      ],
      companiesFields: [],
      showFieldsNewCompany: false,
      region: {
        id: 'NxOpZowo9GmjKqdR',
        title: 'Москва и МО',
      },
      country: {
        id: 'NxOpZowo9GmjKqdR',
        title: 'Россия',
      },
      city: {
        id: 'NxOpZowo9GmjKqdR',
        title: 'г. Москва',
      },
      ownership: '',
      timer: null,
      editCompany: false,
    }
  },
  computed: {
    organization() {
      return this.$store.state.user.organization
    },
    profile() {
      return this.$store.state.user.profile
    },
  },
  watch: {
    organization() {
      this.getCompanyData()
    },
  },
  mounted() {
    this.getCompanyData()
  },
  methods: {
    getCompanyData() {
      this.companyInfo = {
        type: 'company',
        name: this.organization.title,
        phone: formattedPhone(this.organization.phone),
        email: this.profile.email,
        telegram: '8 912 123 45 67',
        id: this.organization.number,
        status: 'Активный',
        img: require('@/assets/images/user.webp'),
      }

      this.companiesFields = [
        {
          title: 'Контакты',
          data: [
            {
              name: 'Название',
              value: this.organization.title,
              type: 'text',
              new: false,
              read: false,
            },
            {
              name: 'Телефон',
              value: formattedPhone(this.organization.phone),
              type: 'phone',
              new: false,
              read: false,
            },
            {
              name: 'Email',
              value: this.organization.email,
              type: 'email',
              new: false,
              read: false,
            },
          ],
          possibleToCreate: [
            {
              name: 'Контакт',
            },
          ],
        },
      ]
    },
    updateCurrentFields(data) {
      this.currentFields = data
    },
    getCompanies() {
      this.$store.commit('profile/getCompaniesSearch', [])
      clearTimeout(this.timer)
      this.timer = null
      this.timer = setTimeout(() => {
        ProfileService.getCompaniesSearch(this.newCompanyFields[0].value)
      }, 500)
    },
    createCompany() {
      ProfileService.createCompany({
        title: this.newCompanyFields[1].value,
        inn: this.newCompanyFields[0].value,
        country_id: this.country.id,
        region_id: this.region.id,
        city_id: this.city.id,
        organization_ownership_id: this.ownership.id,
      })
        .then(r => {
          this.showFieldsNewCompany = false
          this.connectingUserToCompany(r.data.data.id)
          this.$emit('update-profile')
          this.$toast.success(messages.createOrganization)
        })
        .catch(e => {
          if (e.request.status === 417) {
            this.$toast.error(e.response.data.message)
          } else {
            this.$toast.error(JSON.parse(e.request.responseText).message)
          }
        })
    },
    connectingUserToCompany(id = null) {
      UserService.updateProfile({
        organizations: [id === null ? this.$store.state.profile.companiesSearch[0].id : id],
      })
        .then(() => {
          this.$store.commit('profile/readProfile', false)

          UserService.updateProfile({
            priority_organization_id: id === null ? this.$store.state.profile.companiesSearch[0].id : id,
          })
            .then(r => {
              this.$store.commit('profile/readProfile', false)
              PeopleAndCompaniesService.getCompany(r.data.data.human.data.priority_organization_id)
                .then(r => {
                  this.$store.commit('user/getOrganization', r.data.data)
                })
                .catch(e => {
                  errorHandler(e)
                })
              this.$toast.success(messages.connectingUserToCompany)
            })
            .catch(e => {
              if (e.request.status === 417) {
                this.$toast.error(e.response.data.message)
              } else {
                this.$toast.error(JSON.parse(e.request.responseText).message)
              }
            })
        })
        .catch(e => {
          if (e.request.status === 417) {
            this.$toast.error(e.response.data.message)
          } else {
            this.$toast.error(JSON.parse(e.request.responseText).message)
          }
        })
      this.showFieldsNewCompany = false
    },
    updateCompany() {
      this.$refs.companyForm.validate().then(success => {
        if (success) {
          peopleAndCompaniesService
            .updateCompany(this.organization.id, {
              title: this.companiesFields[0].data[0].value,
              phone: phoneTrim(this.companiesFields[0].data[1].value),
              email: this.companiesFields[0].data[2].value,
            })
            .then(() => {
              this.$toast.success(toastMessage.updateOrganization)
              this.editCompany = false
            })
            .catch(e => {
              if (e.request.status === 417) {
                this.$toast.error(e.response.data.message)
              } else {
                this.$toast.error(JSON.parse(e.request.responseText).message)
              }
            })
        } else {
          this.$toast.error(toastMessage.errorValidForm)
        }
      })
    },
    cancelCompany() {
      this.editCompany = false
      this.getCompanyData()
    },
  },
}
</script>

<style lang="scss">
.new-company {
  width: 470px;
  margin-top: 12px;

  &__input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;

    &__error {
      font-size: 10px;
      color: $color-red;
      margin: 5px 0 0 0;
    }
  }

  &__column {
    flex: 1;

    &.title {
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      color: $color-gull-gray;
      margin-right: 10px;
      padding-top: 5px;
    }

    &--second {
      flex: 1;
      cursor: pointer;
    }

    &--select {
      width: 244px;
      justify-content: space-between !important;
      align-items: center !important;
    }
  }

  &__row {
    display: flex;
    align-items: flex-start;
    align-self: flex-start;
    align-content: inherit;
    text-align: left;
    justify-content: flex-start;
    margin-bottom: 8px;
  }
}
</style>
