export const sidebars = {
  namespaced: true,

  state: {
    leftSidebar: false,
    rightSidebar: false,
  },

  mutations: {
    changeLeftSidebar(state, data) {
      state.leftSidebar = data
    },
    changeRightSidebar(state, data) {
      state.rightSidebar = data
    },
  },
}
